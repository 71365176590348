import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from "@mui/material";
import { getOrderDetailsByFullfilmentId } from "../../actions/reconActions";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  maxHeight: "80vh", // Set max height
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Make modal scrollable
};

const showToastMessage = (msg, isError) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const ReconModal = ({ open, setOpen, f_id }) => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [custDetails, setCustDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setOrderDetails([]);
      setLoading(true);
      try {
        const data = await getOrderDetailsByFullfilmentId("fulfillment_id", f_id);
        if (data.statusCode === 200) {
          setOrderDetails(data.body);
          setCustDetails({
            name: data.body[0].billing_customer_name + " " + data.body[0].billing_last_name,
            phone: data.body[0].billing_phone,
            Email: data.body[0].billing_email,
            address: data.body[0].billing_address + " " + data.body[0].billing_address_2,
            total: data.body[0].total,
          });
        } else {
          showToastMessage("No data available for the selected fulfillment_id.", true);
          setCustDetails(null);
          setOrderDetails([]);
        }
      } catch (e) {
        showToastMessage("No data available for the selected fulfillment_id", true);
        console.log("Error", e);
      } finally {
        setLoading(false);
      }
    };

    f_id && fetchData();
  }, [f_id]);

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        {loading && (
          <Box
            style={{
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {orderDetails ? (
          orderDetails.map((order) => (
            <React.Fragment key={order.id}>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  backgroundColor: "#fbefca",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 500 }}>
                  Customer:{" "}
                  <Typography variant="subtitle1" component="span" sx={{ fontWeight: 400 }}>
                    {custDetails && custDetails.name ? custDetails.name : "N/A"}
                  </Typography>
                </Typography>

                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 500, mt: 1 }}>
                  Phone:{" "}
                  <Typography variant="subtitle1" component="span" sx={{ fontWeight: 400 }}>
                    {custDetails && custDetails.phone ? custDetails.phone : "N/A"}
                  </Typography>
                </Typography>

                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 500, mt: 1 }}>
                  Email:{" "}
                  <Typography variant="subtitle1" component="span" sx={{ fontWeight: 400 }}>
                    {custDetails && custDetails.Email ? custDetails.Email : "N/A"}
                  </Typography>
                </Typography>

                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 500, mt: 1 }}>
                  Total (₹):{" "}
                  <Typography variant="subtitle1" component="span" sx={{ fontWeight: 400 }}>
                    {custDetails && custDetails.total ? custDetails.total : "N/A"}
                  </Typography>
                </Typography>

                <Typography variant="subtitle1" component="p" sx={{ fontWeight: 500, mt: 1 }}>
                  Address:{" "}
                  <Typography variant="subtitle1" component="span" sx={{ fontWeight: 400 }}>
                    {custDetails && custDetails.address ? custDetails.address : "N/A"}
                  </Typography>
                </Typography>
              </Box>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="order details table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Product ID</TableCell>
                      <TableCell>Selling Price</TableCell>
                      <TableCell>Units</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.order_items?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.name || "N/A"}</TableCell>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell>
                          <img src={item.url} alt={item.sku} style={{ width: "50px", height: "50px" }} />
                        </TableCell>
                        <TableCell>{item.product_id}</TableCell>
                        <TableCell>{item.selling_price}</TableCell>
                        <TableCell>{item.units}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </React.Fragment>
          ))
        ) : (
          <Typography>No order details available</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ReconModal;
