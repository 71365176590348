import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPerDaySalesData } from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import RedirectLogin from "../RedirectLogin";
import { PageSkeleton } from "../dashboard/helperComponents";
import FabricPOPerFabric from "../poWidgets/fabricPO/FabricPOPerFabric";
import {
  getMaterialFabricList,
  getVendorMaterialList,
} from "../../actions/materialActions";

const FabricPO = () => {
  const dispatch = useDispatch();
  const [vendorMaterials, setVendorMaterials] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { perDaySalesData } = useSelector((state) => state.orderDetails);
  const { materialFabricList } = useSelector((state) => state.inventoryDetails);
  const inventory = useSelector((state) => state.inventoryDetails.stock);

  useEffect(() => {
    if (!perDaySalesData) {
      dispatch(getPerDaySalesData());
    }
    if (!inventory.length > 0) dispatch(allInventory());
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
  }, []);

  useEffect(() => {
    getVendorMaterialList("001")
      .then((data) => {
        if (data.statusCode === 200) {
          const materials = data.body;
          console.log("materials", materials);
          //get all the fabrics
          setVendorMaterials(materials);
        }
      })
      .catch((e) => {
        console.log("err", e);
      });
  }, []);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!perDaySalesData ||
          !materialFabricList.length > 0 ||
          !vendorMaterials.length > 0 ||
          !inventory.length > 0 ? (
            <PageSkeleton />
          ) : (
            <FabricPOPerFabric vendorMaterials={vendorMaterials} />
          )}
        </>
      )}
    </>
  );
};

export default FabricPO;
