import {
  TextField,
  Button,
  Stack,
  TableFooter,
  TablePagination,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-toastify";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DatePicker.css";
import DatePicker from "./DatePicker";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import {
  getAllReconDetailsByDateRange,
  getReconDetailsByFulfillmentId,
} from "../../actions/reconActions";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const formatDate = (date) => {
  return `${date.split("T")[0]}T00:00:00+05:30`;
};

const LastDateRangeRecon = () => {
  const [loading, setLoading] = useState(false);
  const [fulfillmentId, setFulfillmentId] = useState("");
  const [reconData, setReconData] = useState([]);
  const date30DaysAgo = formatDate(
    new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
  );
  const dateToday = formatDate(new Date().toISOString());
  const [date, setDate] = useState({
    startDate: date30DaysAgo,
    endDate: dateToday,
  });

  ///////////
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reconData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
////////////
  console.log("date", date);
  const handleIdChange = (event) => {
    setFulfillmentId(event.target.value);
  };
  const showToastMessage = (msg, isError) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const FetchReconDetails = async () => {
    try {
      setLoading(true);
      const startDate = date.startDate;
      const endDate = date.endDate;
      const result = await getAllReconDetailsByDateRange(startDate, endDate);
      if (result && result.body.length > 0) {
        const data = result.body.map((item) => ({
          fulfillment_id:
            "fulfillment_id" in item ? item.fulfillment_id : "N/A",
          created_at:
            "created_at" in item
              ? new Date(item.created_at).toLocaleString("en-in")
              : "N/A",
          processed_at:
            "processed_at" in item
              ? new Date(item.processed_at).toLocaleString("en-in")
              : "N/A",
          line_items: "line_items" in item ? item.line_items : "N/A",
          received_items: "rcvd_items" in item ? item.rcvd_items : "N/A",
          type: "type" in item ? item.type : "N/A",
        }));
        setReconData(data);
        console.log(result, "Result");
        setLoading(false);
        showToastMessage();
      } else {
        showToastMessage("Enter Both the Dates !", true);
        setReconData("");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetcing Data", error);
      setLoading(false);
    }
  };

  const FetchReconDetailsOfFulfillmentId = async () => {
    try {
      setLoading(true);
      const result = await getReconDetailsByFulfillmentId(fulfillmentId);
      if (result && result.body.length > 0) {
        const data = result.body.map((item) => ({
          fulfillment_id:
            "fulfillment_id" in item ? item.fulfillment_id : "N/A",
          created_at:
            "created_at" in item
              ? new Date(item.created_at).toLocaleString("en-in")
              : "N/A",
          processed_at:
            "processed_at" in item
              ? new Date(item.processed_at).toLocaleString("en-in")
              : "N/A",
          line_items: "line_items" in item ? item.line_items : "N/A",
          received_items: "rcvd_items" in item ? item.rcvd_items : "N/A",
          type: "type" in item ? item.type : "N/A",
        }));
        setReconData(data);
        console.log(result, "Result");
        setLoading(false);
        showToastMessage();
      } else {
        showToastMessage("Enter Both the Dates !", true);
        setReconData("");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetcing Data", error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <h2>Last Recon Orders</h2>
      <Stack
        direction={"row"}
        gap={2}
        my={2}
        sx={{
          alignItems: "center",
        }}
      >
        <Box>
          <h3>Pick the Date Range</h3>
          <Stack direction={"row"} gap={2}>
            <DatePicker setDate={setDate} />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={FetchReconDetails}
              >
                Search
              </Button>
            )}
          </Stack>
        </Box>
        <h3>- or -</h3>
        <Box>
          <h5>Enter the Fulfillment ID</h5>
          <Stack
            direction={"row"}
            gap={1}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              variant="outlined"
              value={fulfillmentId}
              onChange={handleIdChange}
              style={{ minWidth: 200 }}
              size="small"
            />

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={FetchReconDetailsOfFulfillmentId}
              >
                Search
              </Button>
            )}
          </Stack>
        </Box>
      </Stack>

      {reconData && reconData.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fullfillment ID</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Processed At</TableCell>
                <TableCell align="center">Line Items</TableCell>
                <TableCell align="center">Received Items</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? reconData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : reconData
              ).map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.fulfillment_id}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">
                    <Box>{row.created_at}</Box>
                  </TableCell>
                  <TableCell align="center">{row.processed_at}</TableCell>
                  <TableCell align="center">
                    {Array.isArray(row.line_items) ? (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {row.line_items.map((item, i) => {
                          return <Chip key={i} label={item.sku} />;
                        })}
                      </Box>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  {/* <TableCell>NA</TableCell> */}
                  <TableCell align="center">
                    {Array.isArray(row.received_items) ? (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {row.received_items.map((item, i) => {
                          return <Chip key={i} label={item.sku} />;
                        })}
                      </Box>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={reconData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LastDateRangeRecon;
