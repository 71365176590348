import React, { useEffect, useState } from "react";
import { AgCharts } from "ag-charts-react";
import { Paper } from "@mui/material";
const SalesModal = ({ selected }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (selected) {
      const data = selected.sales;
      const chartData = Object.keys(data)
        .map((key) => ({
          sales: key,
          value: data[key],
        }))
        .sort((a, b) => parseInt(b.sales) - parseInt(a.sales));

      const optionsSet = {
        data: chartData,
        series: [
          {
            xKey: "sales",
            yKey: "value",
            title: "Sales vs Value",
            label: {
              enabled: true,
              formatter: (params) => `${params.datum.value}`,
            },
            tooltip: {
              enabled: true,
              renderer: (params) => {
                return {
                  content: `Sales: ${params.datum.sales}, Value: ${params.datum.value}`,
                };
              },
            },
          },
        ],
        axes: [
          {
            type: "category",
            position: "bottom",
            title: {
              text: "Sales",
            },
          },
          {
            type: "number",
            position: "left",
            title: {
              text: "Values",
            },
          },
        ],
      };
      setOptions(optionsSet);
    }
  }, [selected]);

  return (
    <Paper sx={{ p: 2, my: 2 }}>
      {selected ? (
        <h2 style={{ textAlign: "center" }}>{selected.name}</h2>
      ) : (
        <h3 style={{ textAlign: "center" }}>select a material to display</h3>
      )}
      {/* <div style={{ width: '100%', height: '500px' }}> */}
      <AgCharts options={options} />
    </Paper>
    // </div>
  );
};
export default SalesModal;
