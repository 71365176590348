import { Button, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import DateSelector from "../ordersWidgets/DateSelector";
import { format, subDays } from 'date-fns';
import React, { useState, useEffect } from "react";
import ReconcillationTable from "./tables/ReconcillationTable"
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { getAllReconDetailsBySingleDate, getAllReconDetailsByDateRange } from "../../actions/reconActions";

const showToastMessage = (msg, isError) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const ByDateRecon = () => {
    const[selectedDate, setSelectedDate] = useState("");
    const[lastMonthData, setLastMonthData] = useState([]);
    const[selectedDateOrdersData, setSelectedDateOrdersData] = useState(null);
    const [aggregateData, setAggregateData] = useState([]);
    const[loading, setLoading] = useState(false);
    
    
    // Generate an array of date strings for last 30 days
    const generateLast30Days = () => {
        const dates = [];
        for (let i = 0; i < 30; i++) {
          const date = subDays(new Date(), i);
          dates.push(format(date, 'yyyy-MM-dd')); 
        }
        return dates;
      };
    
    const last30Days = generateLast30Days();


    useEffect(() => {
        const fetchData = async () => {
            try{
                setLoading(true);
                const data = await getAllReconDetailsByDateRange(last30Days[last30Days.length - 1], last30Days[0]);  
                if(data.statusCode===200){
                    setLastMonthData(data.body);
                    setLoading(true);
                } else {
                  showToastMessage("No data available for the selected date.", true);
                  setLoading(false);
                }
            }catch(e){
                showToastMessage("No data available for the selected date.", true);
                console.log("Error", e);
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);
    
    

    useEffect(() => {
        if (lastMonthData.length > 0) {
             // Calculate aggregate data from 
            const aData = last30Days.reduce((acc,date)=>{
                const allOrdersbyDate = lastMonthData.filter((order)=> order.created_at?.split("T")[0] === date);
                const rcvdOrders = allOrdersbyDate.filter((f) => "processed_at" in f);
                
                const newObj = {
                    day : date,
                    orders : allOrdersbyDate,
                    mark : rcvdOrders.length < allOrdersbyDate.length
                }
                acc.push(newObj);
                return acc;
            }, [])

            setAggregateData(aData)
            setLoading(false);
          }
    }, [lastMonthData]);

    // Clicking one of the last 30 days chip
    const dateHandleClick = (date) => {
        setSelectedDate(date);
        setSelectedDateOrdersData(aggregateData.filter(item=>item.day===date)[0])
    }

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
  };

  // submiting the calender button
  const handleDateSubmit = async () => {
      const isWithinLast30Days = last30Days.includes(selectedDate);

      if (isWithinLast30Days) {
          dateHandleClick(selectedDate);
      } else {
          try {
              const data = await getAllReconDetailsBySingleDate(selectedDate);  
              if (data.statusCode === 200) {
                  setSelectedDateOrdersData({
                      day: selectedDate,
                      orders: data.body,
                      mark: data.body.length === 0, 
                  });
              } else {
                  showToastMessage("No data available for the selected date.", true);
                  setSelectedDateOrdersData(null);
              }
          } catch (e) {
              showToastMessage("Failed to fetch data for the selected date.", true);
              setSelectedDateOrdersData(null);
              console.log("Error", e);
          }
      }
  };

    
  

  return (
    <Box>
      <h2>Reconcillation</h2>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "8px",
        }}
      >
        {!loading ? aggregateData.map((date, id) => {
          return (
            <Chip
              key={id}
              label={new Date(date.day).toLocaleDateString("IN")}
              onClick = {() => dateHandleClick(date.day)}
              style={{
                flexBasis: "calc(10% - 8px)",
                textAlign: "center",
                backgroundColor: date.mark ? "#ec0000" : "green", 
                color: "white", 
                border: '1px solid', 
              }}
            />
          );
        }): <Box style={{"height": "100%", "width": "100%", "justifyContent": "center", "alignItems": "center", "display": "flex"}}> <CircularProgress/></Box>}
      </Box>

      <Box>
        <h4>Select a Date or Enter a Date : </h4>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box>
          <input
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
            <Button onClick={handleDateSubmit}>Submit</Button>
        </Box>
        
      </Box>
      {selectedDateOrdersData && <ReconcillationTable data={selectedDateOrdersData}/>}
    </Box>
  );
};

export default ByDateRecon;

