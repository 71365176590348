import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  allShopifyInventory,
  getThreeUnfulfilledProductsFilteredData,
} from "../../actions/ordersActions";
import { allProducts } from "../../actions/productsActions";
import { allInventory } from "../../actions/inventoryActions";
import UnfulfilledOrdersComponent from "../ordersWidgets/UnfulfilledOrdersComponent";
import RedirectLogin from "../RedirectLogin";
import { Skeleton } from "@mui/material";
import { PageSkeleton } from "../dashboard/helperComponents";
import "./index.css";
import { getProcessedQuantity } from "../../actions/POActions";

const UnfulfilledOrderProducts = () => {
  const auth = useSelector((state) => state.auth);
  const { filtered3MProductsData } = useSelector((state) => state.orderDetails);
  const products = useSelector((state) => state.productDetails.products);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );
  const { processedQntyList } = useSelector((state) => state.PO);
  const dispatch = useDispatch();
  console.log("inventory", inventory);
  useEffect(() => {
    if (!auth) return;
    if (!filtered3MProductsData)
      dispatch(getThreeUnfulfilledProductsFilteredData());
    if (!products.length > 0) dispatch(allProducts());
    if (!inventory.length > 0) dispatch(allInventory());
    if (!shopifyInventory.length > 0 && auth) dispatch(allShopifyInventory());
    if (!processedQntyList.length > 0 && auth) dispatch(getProcessedQuantity());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!filtered3MProductsData ||
          !products.length > 0 ||
          !processedQntyList.length > 0 ||
          !inventory.length > 0 ? (
            <PageSkeleton />
          ) : (
            <UnfulfilledOrdersComponent />
          )}
        </>
      )}
    </>
  );
};

export default UnfulfilledOrderProducts;
