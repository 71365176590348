import { useState, useEffect, useCallback } from "react";
import {
  MenuItem,
  Box,
  Button,
  Paper,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import AllQueryFilterTable from "../ordersWidgets/AllQueryFilterTable";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import TicketsAssignReport from "../ticketWidgets/TicketsAssignReport";
import TicketsNav from "../ticketWidgets/TicketsNav";
import { searchTicket } from "../../actions/ticketActions";
import { toast } from "react-toastify";
//import { Dashboard } from '@mui/icons-material'
const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: "lightblue",
    padding: "28px 14px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
}));

const CaseStatusArray = ["All", "new", "open", "closed", "reopen"];
const LastSendedByArray = ["All", "None", "customer", "customercare"];
const TicketAgeArray = ["All", "0-5", "6-10", "11-15", "15+"];
const PriorityArray = ["All", "Low", "Medium", "High", "None"];
const PaymnentStatusArray = ["All", "COD", "Paid"];
const CategoryArray = [
  "All",
  "Exchanges",
  "Delivery & Order Status",
  "Order Issues",
  "Payment & Vouchers",
  "Order Cancellation",
  "Order Modification & Issues",
];

const returnExchange = [
  "How to initiate the exchange?",
  "I want to exchange but I'm unable to do it",
  "I have initiated the exchange request, where and when will I get the confirmation ?",
  "My exchange request has been approved, When will the products be picked up?",
  "My exchange request has been rejected, What to do next?",
  "My returned products/product has been picked up. Where and when will I receive the credit voucher?",
  "I'm unable to use my Gift card/credit voucher",
  "Why is my pickup not yet done?",
  "I want the exchange for the second time for the same product.",
  "My exchange period has expired. What can I do?",
];

const deliveryOrder = [
  "How can I track my order?",
  "I want to return my order",
  "Where is my order?",
  "Is Cash on Delivery available?",
];

const orderIssues = [
  "How do I cancel my order my COD order?",
  "How do I cancel my order my Prepaid order?",
  "How can I change my delivery address?",
  "I'm unable to place my order",
  "I have ordered the wrong size/color. How can I change the size/color now?",
  "I have already placed the COD but didn't receive any confirmation.",
  "Have received partial order, needed information about the unfulfilled products?",
  "I am unable to login to my account as I created two accounts using my phone number and the other my email id. Please help",
];

const paymentVoucher = [
  "How to apply voucher/giftcard?",
  "How do I pay for my order?",
  "What is the validity of the giftcard?",
];

const searchByList = ["order Id", "customer Id"];

const AllQueryFilter = ({ tickets, emailList, users }) => {
  const allQuestions = ["All"].concat(
    returnExchange.slice(1),
    deliveryOrder.slice(1),
    orderIssues.slice(1),
    paymentVoucher.slice(1)
  );

  const [ticketSection, setTicketSection] = useState("All");
  const [data, setData] = useState(tickets);
  const [openFilter, setOpenFilter] = useState(false);
  const [resetPage, setResetPage] = useState(0);
  const [searchType, setSearchType] = useState("order Id");
  const [searchId, setSearchId] = useState("");
  const [messageStatus, setMessageStatus] = useState("All");
  const [caseStatus, setCaseStatus] = useState("All");
  const [categoryType, setCategoryType] = useState("All");
  const [questions, setQuestions] = useState(allQuestions);
  const [paymentStatus, setPaymentStatus] = useState("All");
  const [questionSelected, setQuestionSelected] = useState("All");
  const [ticketAgeSelected, setTicketAgeSelected] = useState("All");
  const [prioritySelected, setPrioritySelected] = useState("All");
  const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
  const [createdStartDate, setCreatedStartDate] = useState("");
  const [createdEndDate, setCreatedEndDate] = useState(new Date());
  const [updatedStartDate, setUpdatedStartDate] = useState("");
  const [updatedEndDate, setUpdatedEndDate] = useState(new Date());
  const [sortDirection, setSortDirection] = useState("Descending");
  const [searchTicketId, setSearchTicketId] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const auth = useSelector((state) => state.auth);

  const handleCancelFilter = useCallback(() => {
    const filtered = tickets.filter(
      (t) =>
        (ticketSection === "All" && t.package_status !== "closed") ||
        (ticketSection === "New" && t.package_status === "new") ||
        (ticketSection === "Open & Not Replied" &&
          t.package_status === "open" &&
          !("lastSendedBy" in t)) ||
        (ticketSection === "Replied & Open" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customer") ||
        (ticketSection === "Replied by Customercare" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customercare") ||
        (ticketSection === "reopen c" && t.package_status === "reopen")
    );
    setData(filtered);

    setIsFilteredEmpty(filtered.length > 0 ? false : true);
    setSearchType("order Id");
    setSearchId("");
    setMessageStatus("All");
    setCaseStatus("All");
    setCategoryType("All");
    setQuestions(allQuestions);
    setQuestionSelected("All");
    setPrioritySelected("All");
    setPaymentStatus("All");
    setCreatedStartDate("");
    setCreatedEndDate(
      new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
    );
    setUpdatedStartDate("");
    setUpdatedEndDate(
      new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
    );
    setResetPage(Math.random());
  }, [tickets, ticketSection]);

  useEffect(() => {
    let questionFiltered = allQuestions;
    if (categoryType === "Exchanges") {
      questionFiltered = returnExchange;
    } else if (categoryType === "Delivery & Order Status") {
      questionFiltered = deliveryOrder;
    } else if (categoryType === "Order Issues") {
      questionFiltered = orderIssues;
    } else if (categoryType === "Payment & Vouchers") {
      questionFiltered = paymentVoucher;
    }
    setQuestions(questionFiltered);
  }, [categoryType]);

  const handleCheck = () => {
    const msInHour = 1000 * 60 * 60;

    const filtered = tickets.filter((t) => {
      return (
        Math.round(Math.abs(new Date() - new Date(t.updated_at)) / msInHour) >
          48 &&
        t.package_status === "open" &&
        (!("lastSendedBy" in t) || t.lastSendedBy === "customer")
      );
    });
    setData(filtered);
  };

  const handleFilter = () => {
    setIsFilteredEmpty(false);
    const filtered = tickets.filter(
      (t) =>
        (ticketSection === "All" && t.package_status !== "closed") ||
        (ticketSection === "New" && t.package_status === "new") ||
        (ticketSection === "Open & Not Replied" &&
          t.package_status === "open" &&
          !("lastSendedBy" in t)) ||
        (ticketSection === "Replied & Open" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customer") ||
        (ticketSection === "Replied by Customercare" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customercare") ||
        (ticketSection === "reopen c" &&
          t.package_status === "reopen" &&
          t.lastSendedBy === "customer") ||
        (ticketSection === "reopen cc" &&
          t.package_status === "reopen" &&
          t.lastSendedBy === "customercare")
    );
    console.log("filtered", filtered);
    const searchFiltered = filtered.filter(
      (t) =>
        (searchType === "order Id" &&
          String(t.original_order_id).startsWith(searchId)) ||
        (searchType === "order Id" &&
          String(t.order_id).startsWith(searchId)) ||
        (searchType === "customer Id" &&
          String(t.customer_id).startsWith(searchId))
    );

    const lastSendedFiltered = searchFiltered.filter(
      (t) =>
        messageStatus === "All" ||
        t.lastSendedBy === messageStatus ||
        (messageStatus === "None" && !("lastSendedBy" in t))
    );

    const questionFiltered = lastSendedFiltered.filter(
      (t) =>
        (categoryType === "All" || t.category === categoryType) &&
        (questionSelected === "All" || t.question === questionSelected) &&
        (caseStatus === "All" || t.package_status === caseStatus)
    );

    const createdFiltered = questionFiltered.filter(
      (t) =>
        !createdStartDate ||
        (new Date(new Date(createdStartDate).toLocaleDateString()) <=
          new Date(new Date(t.created_at).toLocaleDateString()) &&
          new Date(new Date(createdEndDate).toLocaleDateString()) >=
            new Date(new Date(t.created_at).toLocaleDateString()))
    );

    const updatedFiltered = createdFiltered.filter(
      (t) =>
        !updatedStartDate ||
        (new Date(new Date(updatedStartDate).toLocaleDateString()) <=
          new Date(new Date(t.updated_at).toLocaleDateString()) &&
          new Date(new Date(updatedEndDate).toLocaleDateString()) >=
            new Date(new Date(t.updated_at).toLocaleDateString()))
    );

    const ticketAgeFiltered = updatedFiltered.filter(
      (t) =>
        ticketAgeSelected === "All" ||
        (ticketAgeSelected === "0-5" && t.ticketAge >= 0 && t.ticketAge <= 5) ||
        (ticketAgeSelected === "6-10" &&
          t.ticketAge >= 6 &&
          t.ticketAge <= 10) ||
        (ticketAgeSelected === "11-15" &&
          t.ticketAge >= 11 &&
          t.ticketAge <= 15) ||
        (ticketAgeSelected === "15+" && t.ticketAge > 15)
    );

    const sorted =
      sortDirection === "Descending"
        ? ticketAgeFiltered
        : ticketAgeFiltered.reverse();

    const prioritySorted = sorted.filter(
      (t) =>
        prioritySelected === "All" ||
        t.priority === prioritySelected ||
        (!("priority" in t) && prioritySelected === "None")
    );

    const paymentSorted = prioritySorted.filter(
      (t) => paymentStatus === "All" || t.payment === paymentStatus
    );

    if (paymentSorted.length === 0) {
      setIsFilteredEmpty(true);
    }
    // credentials sorted
    if (
      auth.name === "customercare" &&
      ticketSection !== "Replied by Customercare" &&
      ticketSection !== "reopen cc"
    ) {
      const userSorted = sorted.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id === auth.user &&
          (!("lastSendedBy" in t) ||
            t.lastSendedBy === "customer" ||
            t.package_status === "new")
      );
      if (userSorted.length === 0) {
        setIsFilteredEmpty(true);
      }
      setData(userSorted);
    } else {
      setData(paymentSorted);
    }
  };

  useEffect(() => {
    if (!tickets.length > 0) return;
    handleFilter();
  }, [tickets, ticketSection]);

  const handleTicketIdSearch = async () => {
    setSearchLoading(true);
    const data = await searchTicket(searchTicketId);
    if (data.status === 200) {
      const t = data.data;
      const differenceTime =
        new Date().getTime() - new Date(t.created_at).getTime();
      const differentDays = Math.round(differenceTime / (1000 * 3600 * 24));
      const createdDate = new Date(t.created_at);
      const updatedDate = new Date(t.updated_at);
      const createdLocal = new Date(
        createdDate.getTime() - createdDate.getTimezoneOffset() * 60000
      ).toLocaleString();
      const updatedLocal = new Date(
        updatedDate.getTime() - updatedDate.getTimezoneOffset() * 60000
      ).toLocaleString();
      const messages = t.messages;
      let messageCount = 0;
      let lastSender = "";
      const chat = messages.slice(2);
      chat.forEach((m) => {
        if (lastSender === m.sendedBy) {
          return;
        }
        messageCount++;
        lastSender = m.sendedBy;
      });
      if (messages.length > 2 && !(t.status === "new")) {
        const sendedBy = messages[messages.length - 1].sendedBy;
        setData([
          {
            ...t,
            messageCount: messageCount,
            package_status:
              t.package_status === "closed"
                ? "closed"
                : t.package_status.split("#")[1],
            executive_id: t.executive_id
              ? t.executive_id.split("#")[0]
              : t.executive_id,
            lastSendedBy: sendedBy,
            created_at: createdLocal,
            updated_at: updatedLocal,
            ticketAge: differentDays,
          },
        ]);
      } else {
        setData([
          {
            ...t,
            messageCount: messageCount,
            package_status:
              t.package_status === "closed"
                ? "closed"
                : t.package_status.split("#")[1],
            executive_id: t.executive_id
              ? t.executive_id.split("#")[0]
              : t.executive_id,
            created_at: createdLocal,
            updated_at: updatedLocal,
            ticketAge: differentDays,
          },
        ]);
      }
    } else {
      toast.error("Cannot Find Ticket Id");
    }
    setSearchLoading(false);
  };

  return (
    <div>
      {tickets.length > 0 && (
        <TicketsNav
          tickets={tickets}
          users={users}
          setOpenFilter={setOpenFilter}
        />
      )}
      {openFilter ? (
        <Box mb={2}>
          <Box mb={1} component={Paper} p={1}>
            <Stack direction={"row"} gap={2}>
              <TextField
                value={searchTicketId}
                onChange={(e) => setSearchTicketId(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <h3
                        style={{
                          textAlign: "center",
                          color: "black",
                        }}
                      >
                        Ticket Id
                      </h3>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                onClick={handleTicketIdSearch}
                disabled={searchLoading}
              >
                Search
              </Button>
            </Stack>
          </Box>
          <Box component={Paper} p={3}>
            <Stack direction="column" gap={2}>
              <Stack direction={"row"}>
                <TextField
                  id={"select"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TextField
                          select
                          InputProps={{
                            style: {
                              fontWeight: 800,
                              textAlign: "center",
                              fontSize: "1.2rem",
                              textTransform: "capitalize",
                            },
                            disableUnderline: true,
                          }}
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                          variant="standard"
                          style={{ width: "140px" }}
                        >
                          {searchByList.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                  value={searchId}
                  onChange={(e) => setSearchId(e.target.value)}
                  variant="outlined"
                  // style={{ width: "200px" }}
                />
              </Stack>
              <Stack direction={"row"} gap={3} sx={{ alignItems: "center" }}>
                <TextField
                  select
                  value={messageStatus}
                  onChange={(e) => setMessageStatus(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Last Sended By
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  className="select"
                >
                  {LastSendedByArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={caseStatus}
                  onChange={(e) => setCaseStatus(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Case Status
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  className="select"
                >
                  {CaseStatusArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={categoryType}
                  onChange={(e) => setCategoryType(e.target.value)}
                  variant="outlined"
                  className="select"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Category
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                >
                  {CategoryArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={"row"} gap={3} sx={{ alignItems: "center" }}>
                <TextField
                  select
                  value={questionSelected}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Questions
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setQuestionSelected(e.target.value)}
                  sx={{
                    width: "300px",
                  }}
                  variant="outlined"
                  className="select"
                >
                  {questions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={ticketAgeSelected}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Ticket Age (Days)
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setTicketAgeSelected(e.target.value)}
                  variant="outlined"
                  className="select"
                >
                  {TicketAgeArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={prioritySelected}
                  onChange={(e) => setPrioritySelected(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Priority
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  className="select"
                >
                  {PriorityArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Payment
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  className="select"
                >
                  {PaymnentStatusArray.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={"row"} gap={2}>
                <TextField
                  variant="outlined"
                  InputProps={{
                    inputProps: { max: createdEndDate },
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Created From
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  value={createdStartDate}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setCreatedStartDate(e.target.value);
                  }}
                />
                <TextField
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Created To
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setCreatedEndDate(e.target.value);
                  }}
                  defaultValue={createdEndDate}
                />
                <TextField
                  variant="outlined"
                  value={updatedStartDate}
                  InputProps={{
                    inputProps: { max: updatedEndDate },
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Updated From
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setUpdatedStartDate(e.target.value);
                  }}
                />
                <TextField
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <h3
                          style={{
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          Updated To
                        </h3>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={(e) => {
                    setUpdatedEndDate(e.target.value);
                  }}
                  defaultValue={updatedEndDate}
                />
              </Stack>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={sortDirection}
                  onChange={(e) => setSortDirection(e.target.value)}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Descending"
                    control={<Radio />}
                    label="Descending"
                  />
                  <FormControlLabel
                    value="Ascending"
                    control={<Radio />}
                    label="Ascending"
                  />
                </RadioGroup>
              </FormControl>
              <Stack
                direction={"row"}
                gap={2}
                sx={{ marginRight: 0, marginLeft: "auto" }}
              >
                <Button onClick={handleCheck}>check for tickets</Button>
                <Button
                  variant="contained"
                  color={"error"}
                  onClick={handleCancelFilter}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleFilter();
                    setResetPage(Math.random());
                  }}
                >
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      ) : (
        ""
      )}
      {data.length === 0 && !isFilteredEmpty ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <img
            src="https://cssbud.com/wp-content/uploads/2022/05/pendulum.gif"
            alt=""
            style={{
              objectFit: "contain",
              marginLeft: "35%",
              marginRight: "65%",
            }}
          />
        </Box>
      ) : (
        <AllQueryFilterTable
          isFilteredEmpty={isFilteredEmpty}
          setTicketSection={setTicketSection}
          setResetPage={setResetPage}
          handleFilter={handleFilter}
          data={data}
          emailList={emailList}
          resetPage={resetPage}
        />
      )}
    </div>
  );
};
export default AllQueryFilter;
