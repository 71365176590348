import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateMaterial } from "../../../actions/materialActions";

const UpdateMaterialForm = ({ material, allMaterials, setMaterials }) => {
  const [width, setWidth] = useState(0);
  const [fabricRate, setFabricRate] = useState(0);
  const [pFabricRate, setPFabricRate] = useState(0);
  useEffect(() => {
    if (
      "width" in material &&
      "fabric_rate" in material &&
      "p_fabric_rate" in material
    ) {
      setWidth(material.width);
      setFabricRate(material.fabric_rate);
      setPFabricRate(material.p_fabric_rate);
    } else {
      setWidth(0);
      setFabricRate(0);
      setPFabricRate(0);
    }
  }, [material]);
  const changeWidth = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setWidth(0);
    } else {
      setWidth(num);
    }
  };
  const changeFabricRate = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setFabricRate(0);
    } else {
      setFabricRate(num);
    }
  };
  const changePFabricRate = (e) => {
    const num = parseInt(e.target.value);
    if (isNaN(num)) {
      setPFabricRate(0);
    } else {
      setPFabricRate(num);
    }
  };
  const handleUpdate = async () => {
    const payload = {
      company: material.company,
      barcode: material.barcode,
      width,
      fabricRate,
      pFabricRate,
    };
    const data = await updateMaterial(payload);
    if (data.statusCode === 200) {
      const newMaterials = allMaterials.map((m) => {
        if (m.barcode === material.barcode && m.company === material.company) {
          return {
            ...m,
            width,
            fabric_rate: fabricRate,
            p_fabric_rate: pFabricRate,
          };
        } else {
          return m;
        }
      });
      setMaterials(newMaterials);
    }
  };
  return (
    <Box my={1} p={4}>
      <Stack direction={"row"} gap={3}>
        <TextField
          style={{ width: "200px" }}
          label="Width"
          value={width}
          onChange={changeWidth}
          variant="standard"
        />
        <TextField
          style={{ width: "200px" }}
          label="Fabric Rate"
          value={fabricRate}
          onChange={changeFabricRate}
          variant="standard"
        />
        <TextField
          style={{ width: "200px" }}
          label="Digital Fabric Rate"
          value={pFabricRate}
          onChange={changePFabricRate}
          variant="standard"
        />
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "end",
        }}
      >
        <Button variant="contained" sx={{ my: 1 }} onClick={handleUpdate}>
          Update
        </Button>
      </Stack>
    </Box>
  );
};

export default UpdateMaterialForm;
