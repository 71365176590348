import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FabricPOTable from "./FabricPOTable";
const days30 = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];
const days45 = Array.from({ length: 45 }, (v, i) =>
  (i + 1).toString()
).reverse();

const FabricDataGenerate = ({ materialData, vendorMaterials }) => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [materialWithPO, setMaterialWithPO] = useState([]);
  const { perDaySalesData } = useSelector((state) => state.orderDetails);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  //   const data = materialData.reduce((m)=> {
  //     const fabricName = m.fabricName
  //     const colorName = m.colorName
  //     const fabricId = m.fabricId
  //     const colorId = m.colorId

  // },[])
  useEffect(() => {
    const salesData = perDaySalesData.sales_data;

    // create total stock and consumption for each
    const inv = inventory.reduce((acc, i) => {
      const materials = "material" in i ? i.material : [];
      materials.forEach((d) => {
        const found = acc.find((ac) => ac.name === d.name);
        if (found) {
          const findSales = salesData.find((s) => s.sku === i.SKU);
          let totalSaleConsumption = 0;
          if (findSales) {
            found.perDaySalesData = days45.reduce((acc, day) => {
              const amt = findSales[day] ? findSales[day].total : 0;
              acc[day] = found.perDaySalesData[day] + amt;
              return acc;
            }, {});
            const sales = days30.reduce((acc, day) => {
              const amt = findSales[day] ? findSales[day].total : 0;
              return acc + amt;
            }, 0);
            // console.log("sales", sales, i.materialWeight, i.materialFormula);
            if (i.materialFormula === "IR" && "materialWeight" in i) {
              totalSaleConsumption = parseFloat(
                parseFloat((sales * parseFloat(i.materialWeight)) / 20).toFixed(
                  2
                )
              );
            } else if (i.materialFormula === "IM" && "materialWeight" in i) {
              totalSaleConsumption = parseFloat(
                parseFloat(sales * parseFloat(i.materialWeight)).toFixed(2)
              );
            } else {
              totalSaleConsumption = 0;
            }
          }
          const vendorStock = vendorMaterials
            .filter((v) => v.fabricName === d.type && v.colorName === d.color)
            .reduce((acc, v) => acc + v.amt, 0);
          const inventoryStock = i.count;
          found.totalSaleConsumption = parseFloat(
            parseFloat(
              found.totalSaleConsumption + totalSaleConsumption
            ).toFixed(2)
          );
          found.vendorStock = found.vendorStock + vendorStock;
          found.inventoryStock = found.inventoryStock + inventoryStock;
        } else {
          //find sale
          //   console.log("sales data", salesData);
          const findSales = salesData.find((s) => s.sku === i.SKU);
          let totalSaleConsumption = 0;
          let perDaySalesData = days45.reduce(
            (acc, day) => ({ ...acc, [day]: 0 }),
            {}
          );
          if (findSales) {
            perDaySalesData = days45.reduce((acc, day) => {
              const amt = findSales[day] ? findSales[day].total : 0;
              acc[day] = amt;
              return acc;
            }, {});
            const sales = days30.reduce((acc, day) => {
              const amt = findSales[day] ? findSales[day].total : 0;
              return acc + amt;
            }, 0);
            // console.log("sales", sales, i.materialWeight, i.materialFormula);
            if (i.materialFormula === "IR" && "materialWeight" in i) {
              totalSaleConsumption = parseFloat(
                parseFloat((sales * parseFloat(i.materialWeight)) / 20).toFixed(
                  2
                )
              );
            } else if (i.materialFormula === "IM" && "materialWeight" in i) {
              totalSaleConsumption = parseFloat(
                parseFloat(sales * parseFloat(i.materialWeight)).toFixed(2)
              );
            } else {
              totalSaleConsumption = 0;
            }
          }
          const vendorStock = vendorMaterials
            .filter((v) => v.fabricName === d.type && v.colorName === d.color)
            .reduce((acc, v) => acc + v.amt, 0);
          const inventoryStock = i.count;

          const newObj = {
            name: d.name,
            color: d.color,
            fabric: d.type,
            totalSaleConsumption,
            inventoryStock,
            vendorStock,
            perDaySalesData,
          };
          acc.push(newObj);
        }
      });
      return acc;
    }, []);
    setData(inv);
  }, [perDaySalesData, inventory, vendorMaterials]);

  useEffect(() => {
    const materialInfos = materialWithPO.reduce((acc, m) => {
      const row = data.find(
        (d) => d.fabric === m.fabricName && d.color === m.colorName
      );
      if (row) {
        const totalSaleConsumption = row.totalSaleConsumption;
        const perDaySalesData = row.perDaySalesData;
        const perDayConsumption = days45.reduce((acc, day) => {
          if (day === "45") {
            return acc;
          } else {
            const newAmt = (acc + perDaySalesData[day]) / 2;
            return parseFloat(parseFloat(newAmt).toFixed(2));
          }
        }, perDaySalesData["45"]);
        const unit = m.unit;
        const fabricStock = m.unitValue ? m.unitValue : 0;
        const vendorStock = vendorMaterials
          .filter(
            (v) => v.fabricName === m.fabricName && v.colorName === m.colorName
          )
          .reduce((acc, v) => acc + v.amt, 0);
        const totalStock = fabricStock + vendorStock;
        const vendorPO = m.vendorPO;
        const netStock = row.inventoryStock;
        const skuPO = m.skuPO;
        const totalRequired =
          1.5 * totalSaleConsumption -
          (fabricStock + vendorStock + vendorPO + netStock) +
          skuPO;
        const daysOfStockRequired =
          perDayConsumption === 0
            ? 0
            : parseFloat(
                parseFloat(totalRequired / perDayConsumption).toFixed(2)
              );
        const daysOfStockAvailable = 45 - daysOfStockRequired;
        const obj = {
          fabricName: m.fabricName,
          colorName: m.colorName,
          totalSaleConsumption,
          unit,
          fabricStock,
          vendorStock,
          totalStock,
          vendorPO,
          netStock,
          perDaySalesData,
          skuPO,
          totalRequired,
          daysOfStockRequired,
          daysOfStockAvailable,
          perDayConsumption,
        };
        acc.push(obj);
      } else {
        const totalSaleConsumption = 0;
        const perDayConsumption = 0;
        const unit = m.unit;
        const fabricStock = m.unitValue;
        const vendorStock = m.vendorStock;
        const totalStock = fabricStock + vendorStock;
        const vendorPO = m.vendorPO;
        const netStock = 0;
        const skuPO = m.skuPO;
        const totalRequired =
          1.5 * totalSaleConsumption -
          (fabricStock + vendorStock + vendorPO + netStock) +
          skuPO;
        const daysOfStockRequired =
          perDayConsumption === 0
            ? 0
            : parseFloat(
                parseFloat(totalRequired / perDayConsumption).toFixed(2)
              );
        const daysOfStockAvailable = 45 - daysOfStockRequired;
        const obj = {
          fabricName: m.fabricName,
          colorName: m.colorName,
          totalSaleConsumption,
          unit,
          fabricStock,
          vendorStock,
          totalStock,
          vendorPO,
          netStock,
          skuPO,
          totalRequired,
          daysOfStockRequired,
          daysOfStockAvailable,
        };
        acc.push(obj);
      }
      return acc;
    }, []);
    setTableData(materialInfos);
  }, [data, materialWithPO, vendorMaterials]);

  useEffect(() => {
    const newData = materialData.map((m) => ({
      ...m,
      skuPO: 0,
      vendorPO: 0,
    }));
    setMaterialWithPO(newData);
  }, [materialData]);
  return (
    <div>
      <FabricPOTable
        tableData={tableData}
        setMaterialWithPO={setMaterialWithPO}
      />
    </div>
  );
};

export default FabricDataGenerate;
