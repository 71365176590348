import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import {
  allShopifyInventory,
  getTridiurnalSalesData,
} from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import { allProducts } from "../../actions/productsActions";

import NewProductsSalesTable from "../salesWidgets/NewProductsSalesTable";
import RedirectLogin from "../RedirectLogin";
import DateSelector from "../ordersWidgets/DateSelector";
import { PageSkeleton } from "../dashboard/helperComponents";
import "./index.css";

const NewProductSales = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { tridiurnalSalesData, shopifyInventory } = useSelector(
    (state) => state.orderDetails
  );

  const products = useSelector((state) => state.productDetails.products);
  console.log('products',products, 'shopifyInventory', shopifyInventory)

  const inventory = useSelector((state) => state.inventoryDetails.stock);

  const [minDate, setMinDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  ); //START DATE
  const [maxDate, setMaxDate] = useState(
    new Date().toISOString().split("T")[0]
  ); //END DATE
  const [data, setData] = useState([]);
  const [numberOfSelected, setNumberOfSelected] = useState(0);

  useEffect(() => {
    if (!tridiurnalSalesData) {
      dispatch(getTridiurnalSalesData());
    }
    if (!products.length > 0) dispatch(allProducts());
    if (!shopifyInventory.length > 0) dispatch(allShopifyInventory());
    if (!inventory.length > 0) dispatch(allInventory());
  }, [dispatch]);

  const searchSalesData = () => {
    const selectedProducts = products.filter(
      (p) =>
        new Date(p.created_at) >= new Date(minDate) &&
        new Date(p.created_at) <= new Date(maxDate) 
        // && p.status === "active"
    );

    if (selectedProducts.length === 0) {
      toast.success("No New Products Created");
    } else {
      setNumberOfSelected(selectedProducts.length);
      const selectedProIds = selectedProducts.map((p) => p.id);
      console.log("selectedProIds", selectedProIds, selectedProducts);
      console.log("tridiurnalSalesData", tridiurnalSalesData.sales_data);
      // const salesData = tridiurnalSalesData.sales_data.filter((p) =>
      //   selectedProIds.includes(p.id)
      // );
      const salesData = selectedProducts.filter(sp => !!sp.variants.find((v) => v.sku)).map((sp) => {
        const sale = tridiurnalSalesData.sales_data.find((p) => sp.id === p.id);
        if(!sp.variants.find((v) => v.sku)){
          console.log("found",sp)
        }
        if (sale) {
          return {
            ...sale,
            price: sp.variants.find((v) => v.price).price,
            status : sp.status
          };
        } else {
          return {
            id: sp.id,
            name: sp.title,
            price: sp.variants.find((v) => v.price).price,
            sku: sp.variants.find((v) => v.sku).sku,
            total: 0,
            status : sp.status
          };
        }
      });
      console.log("sales data", salesData);
      setData(salesData);
    }
  };

  const clearSelection = () => {
    setData([]);
    setNumberOfSelected(0);
  };

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!tridiurnalSalesData ||
          !inventory.length > 0 ||
          !products.length > 0 ||
          !shopifyInventory.length > 0 ? (
            <PageSkeleton />
          ) : (
            <>
              <h2>Last Updated at: {tridiurnalSalesData.updated_at}</h2>
              <br />
              <br />
              <h4>Select Products published between:</h4>
              <br />
              {numberOfSelected === 0 ? (
                <>
                  <DateSelector
                    handleMinChange={setMinDate}
                    handleMaxChange={setMaxDate}
                  />
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={searchSalesData}
                  >
                    Search sales data
                  </Button>
                </>
              ) : (
                <>
                  <p>
                    {numberOfSelected} new products created between {minDate}{" "}
                    and {maxDate}
                  </p>
                  <Button onClick={clearSelection}>Clear Selection</Button>
                </>
              )}

              {numberOfSelected !== 0 && !data.length > 0 && (
                <h3>Not found, select another date range</h3>
              )}
              <NewProductsSalesTable sales={data} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewProductSales;
