import React from "react";
import ReconReport from "../reverseRecon/ReverseReconReport";
import LastDateRangeRecon from "../reverseRecon/LastDateRangeRecon";
import ByDateRecon from "../reverseRecon/ByDateRecon";

const ReverseReconcilation = () => {
  return (
    <div>
      {/* <LastDateRangeRecon /> */}
      <ByDateRecon />
    </div>
  );
};

export default ReverseReconcilation;
