import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Box,
  MenuItem,
  Container,
  Avatar,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  Modal,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";

import { useDispatch, useSelector } from "react-redux";
import {
  closeRetryBox,
  closeTicket,
  reopenTicket,
  retryTicketResponse,
  sendTicketResponse,
} from "../../actions/ticketActions";
import Template from "./Template";
import ImageViewer from "./ImageViewer";

const ChatModule = ({
  category,
  customerId,
  executiveId,
  messages,
  ticketId,
  email,
  originalTicketId,
  emailList,
  status,
  chover,
  closedBy,
  ticket,
}) => {
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageId, setImageId] = useState("");
  const [prevChats, setPrevChats] = useState([]);
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const loading = useSelector((state) => state.loader.loading);
  const { retryLoading, openRetryBox } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeFormatted = messages.map((m) => {
      const date = new Date(m.time);
      const localDateTime = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toLocaleString();
      return {
        ...m,
        time: localDateTime,
      };
    });
    setPrevChats(timeFormatted);
  }, [messages]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageClose = () => {
    setOpenImage(false);
  };
  const handleRetryClose = () => {
    dispatch(closeRetryBox());
  };
  const handleSend = () => {
    if (!message) return;
    setOpen(false);
    const customerMail = emailList.includes(email) ? null : email;
    const messageFormatted = message
      .replaceAll(
        "https://littleboxindia.com/pages/return-exchange",
        '<a href="https://littleboxindia.com/pages/return-exchange">https://littleboxindia.com/pages/return-exchange</a>'
      )
      .replaceAll(
        ("https://littleboxindia.com/account/login",
        '<a href="https://littleboxindia.com/account/login">https://littleboxindia.com/account/login</a>')
      );

    dispatch(
      sendTicketResponse(
        ticketId,
        messageFormatted,
        customerMail,
        files,
        originalTicketId
      )
    );
  };

  const handleRetry = () => {
    if (!message) return;
    const customerMail = emailList.includes(email) ? null : email;
    dispatch(retryTicketResponse(ticketId, message, customerMail, files));
  };

  const handleReopenCase = async () => {
    dispatch(reopenTicket(ticket));
  };

  const handleCloseCase = () => {
    dispatch(closeTicket(ticketId, originalTicketId, customerId, executiveId));
  };

  const handleChange = (fileArray) => {
    const selectedFilesLength = files.length;
    fileArray.forEach((fileItem, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileItem);
      reader.onload = function () {
        const rawLog = reader.result.split(",")[1];
        const dataSend = {
          id: index + selectedFilesLength,
          size: fileItem.size,
          dataReq: {
            data: rawLog,
            name: fileItem.name,
            type: fileItem.type,
          },
          fname: "uploadFilesToGoogleDrive",
        };
        setFiles((f) => [...f, dataSend]);
      };
    });
  };

  const handleDeleteFiles = (id) => {
    const newFileArray = files
      .filter((f) => f.id !== id)
      .map((file, index) => ({ ...file, id: index }));
    setFiles(newFileArray);
  };

  return (
    <>
      {loading && (
        <div className="load-overlay">
          <h1>Please Wait</h1>
        </div>
      )}
      <div>
        {/* <Container> */}
        <Stack direction="column" spacing={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              borderRadius: "5px",
              paddingTop: "30px",
              backgroundColor: "#f7f7f7",
              boxShadow: "2px -4px #D3D3D3",
              overflow: "scroll",
              gap: 2,
            }}
          >
            <h2
              style={{
                textAlign: "center",
              }}
            >
              Correspondence
            </h2>

            <Stack spacing={2} direction="column">
              {"files" in ticket && ticket.files.length > 0 ? (
                <Box>
                  <Divider />
                  <h3>Images Submitted :</h3>
                  <Stack direction={"row"} gap={2}>
                    {ticket.files.map((f, i) => (
                      <>
                        <Box
                          onClick={() => {
                            setImageId(f.id);
                            setOpenImage(true);
                          }}
                          sx={{
                            "& :hover": {
                              cursor: "pointer",
                              color: "lightblue",
                              textDecoration: "underline",
                            },
                          }}
                        >
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={`https://lh3.google.com/u/0/d/${f.id}`}
                            // src={`https://drive.google.com/thumbnail?id=${f.id}`}
                            alt=""
                          />
                        </Box>
                      </>
                    ))}
                  </Stack>
                </Box>
              ) : (
                ""
              )}

              {prevChats.map((m) => (
                <Stack
                  direction={"column"}
                  gap={1}
                  sx={{
                    padding: "20px",
                    color: m.sendedBy === "customercare" ? "white" : "",
                    backgroundColor:
                      m.sendedBy === "customercare" ? "#2979FF" : "#DCE9FF",
                    border: "1px solid #cfcdcd",
                    borderTopLeftRadius:
                      m.sendedBy === "customercare" ? "30px" : "",
                    borderTopRightRadius:
                      m.sendedBy === "customercare" ? "0px" : "30px",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                    alignSelf: m.sendedBy === "customercare" ? "end" : "start",

                    width: chover ? "400px" : "60vh",
                    "& h3": {
                      padding: 0,
                      margin: 0,
                    },
                  }}
                >
                  <h5
                    style={{
                      textAlign:
                        m.sendedBy === "customercare" ? "end" : "start",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {m.time}
                  </h5>
                  <Divider flexItem />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: String(m.message).replaceAll("\n", "<br>"),
                    }}
                  />
                  {"files" in m && m.files.length > 0 ? (
                    <Box>
                      <Divider />
                      <h3>Attachments :</h3>
                      <Stack direction={"row"} gap={2}>
                        {m.files.map((f, i) => (
                          <>
                            <Box
                              onClick={() => {
                                setImageId(f.id);
                                setOpenImage(true);
                              }}
                              sx={{
                                "& :hover": {
                                  cursor: "pointer",
                                  color: "lightblue",
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              <img
                                style={{ width: "100px", height: "100px" }}
                                // src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                                src={`https://lh3.google.com/u/0/d/${f.id}`}
                                alt=""
                              />
                              {/* <iframe
                                title="img"
                                width="100px"
                                height="100px"
                                src={`https://lh3.google.com/u/0/d/${f.id}`}
                              ></iframe> */}
                            </Box>
                          </>
                        ))}
                      </Stack>
                    </Box>
                  ) : (
                    ""
                  )}
                </Stack>
              ))}

              {status !== "closed" ? (
                <Stack direction="row" gap={2}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleCloseCase}
                  >
                    Close Case
                  </Button>
                  <Button
                    sx={{ marginRight: 0, marginLeft: "auto" }}
                    variant="contained"
                    onClick={handleClickOpen}
                    endIcon={<SendIcon />}
                  >
                    Reply
                  </Button>
                </Stack>
              ) : (
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <h1>This Ticket Is Closed by {closedBy}</h1>
                  <br />
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={handleReopenCase}
                  >
                    <span style={{ fontWeight: 800 }}>Reopen</span>
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
        <Dialog
          fullScreen
          open={open}
          sx={{ margin: "100px", overflow: "hidden" }}
          onClose={handleClose}
        >
          <DialogTitle>Enter Message</DialogTitle>
          <DialogContent>
            <Stack direction={"row"} gap={2} sx={{ height: "100%" }}>
              <TextField
                sx={{ marginTop: "5px" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                autoFocus
                InputProps={{
                  disableUnderline: true,
                }}
                id="filled-textarea"
                aria-expanded={true}
                fullWidth={true}
                minRows={19}
                multiline
                variant="outlined"
              />
              <Box sx={{ height: "100%" }}>
                <Template category={category} setMessage={setMessage} />
              </Box>
            </Stack>
            <Box
              sx={{
                marginBottom: 0,
                marginTop: "auto",
              }}
            >
              {files.map((f) => (
                <Stack
                  marginTop={1.2}
                  direction={"row"}
                  gap={1}
                  alignItems={"center"}
                  sx={{
                    padding: "2px 5px",
                    borderRadius: "10px",
                    bgcolor: "lightgrey",
                    width: "100%",
                  }}
                >
                  <h4 style={{ padding: 0, margin: 0 }}>{f.dataReq.name} </h4>{" "}
                  <ClearIcon
                    onClick={() => handleDeleteFiles(f.id)}
                    sx={{
                      "&:hover": {
                        color: "red",
                        cursor: "pointer",
                        transform: "scale(1.3)",
                      },
                    }}
                  />
                </Stack>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <label style={{ marginRight: "auto", marginLeft: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  padding: "5px",
                  // border: "1px solid lightblue",
                  alignItems: "center",
                  flexDirection: "row",
                  "& :hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <h2>Add Attachments</h2>
                <AttachFileIcon />
              </Box>
              <Box sx={{ display: "none" }}>
                <TextField
                  required
                  variant="outlined"
                  type="file"
                  id="file"
                  // label="Upload Images"
                  onChange={(e) => handleChange([...e.target.files])}
                  inputProps={{
                    multiple: true,
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Box>
            </label>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSend}>Send</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openRetryBox} onClose={handleRetryClose}>
          <DialogTitle>Erorr In Sending Response</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              There was an error in sending your response, please try again
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRetryClose}>Cancel</Button>
            {retryLoading ? (
              <CircularProgress size={"2rem"} sx={{ marginLeft: "10px" }} />
            ) : (
              <Button onClick={handleRetry}>Retry</Button>
            )}
          </DialogActions>
        </Dialog>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageViewer handleClose={handleImageClose} id={imageId} />
        </Modal>
      </div>
    </>
  );
};

export default ChatModule;
