import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getOrdersInS3ByDate,
  getShiprocketOrdersByProcessedAt,
} from "../../actions/ordersActions";
import ShiprocketOrderTable from "../shiprocket/ShiprocketOrderTable";
import OrderReport from "../shiprocket/OrderReport";
import Filter from "../shiprocket/Filter";
import { toast } from "react-toastify";
import {
  createPDFforManifest,
  createPDFforManifestForShiprocket,
} from "../../utils/pdfUtils";
import { createXLforDelhivery } from "../../utils/excelUtils";
import { CSVLink } from "react-csv";

function splitArray(arr, numberOfArrays) {
  if (numberOfArrays < 1) {
    throw new Error("Number of arrays should be greater than or equal to 1");
  }

  const chunkSize = Math.ceil(arr.length / numberOfArrays);
  const result = [];

  for (let i = 0; i < numberOfArrays; i++) {
    const start = i * chunkSize;
    const end = start + chunkSize;
    const chunk = arr.slice(start, end);
    result.push(chunk);
  }

  return result;
}

const ShiprocketOrders = () => {
  const todayDate = new Date().toISOString().split("T")[0];
  // 2024-11-07
  const [date, setDate] = useState(todayDate);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isNoOrders, setIsNoOrders] = useState(false);
  // filter values
  const [statuses, setStatuses] = useState([]);
  const [comments, setComments] = useState([]);
  const [couriers, setCouriers] = useState([]);
  //   select values
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedComment, setSelectedComment] = useState("All");
  const [selectedCourier, setSelectedCourier] = useState("All");
  const [selectedProcessed, setSelectedProcessed] = useState("All");
  const [numberOfParts, setNumberOfParts] = useState(0);
  const [excelData, setExcelData] = useState([]);
  //
  //   use effects
  useEffect(() => {
    if (excelData.length > 0) {
      // csvLink.current.link.click();
      excelData.forEach((e, i) => {
        const csvLink = document.getElementById(`csv-link-${i}`);
        if (csvLink) {
          csvLink.click();
        }
      });
    }
  }, [excelData]);
  //
  const handleSearch = async () => {
    setIsNoOrders(false);
    setLoading(true);
    const ordersInS3 = await getOrdersInS3ByDate(date);
    if (ordersInS3.statusCode === 200) {
      if (ordersInS3.body.length > 0) {
        const processed_date = `${date}T00:00:00+05:30`;
        const data = await getShiprocketOrdersByProcessedAt(processed_date);
        if (data.statusCode === 200) {
          const ordersProcessed = data.data;
          const shOrders = ordersInS3.body
            .filter((o) => o.shipping_partner === "shiprocket")
            .map((o) => {
              const found = ordersProcessed.find(
                (op) => String(op.fulfillment_id) === String(o.fulfillment_id)
              );
              console.log("found", found);
              if (found) {
                return {
                  ...found,
                  isProcessed: true,
                };
              } else {
                return {
                  ...o,
                  isProcessed: false,
                };
              }
            });
          setOrders(shOrders);
          setStatuses(
            shOrders.reduce(
              (acc, o) => {
                const status = o.package_status;
                if (acc.includes(status)) {
                  return acc;
                } else {
                  acc = [...acc, status];
                  return acc;
                }
              },
              ["All"]
            )
          );
          setComments(
            shOrders.reduce(
              (acc, o) => {
                if (o.shiprocket_msg) {
                  const message = o.shiprocket_msg;
                  if (acc.includes(message)) {
                    return acc;
                  } else {
                    acc = [...acc, message];
                    return acc;
                  }
                }
                return acc;
              },
              ["All"]
            )
          );
          setCouriers(
            shOrders.reduce(
              (acc, o) => {
                if (o.courier_name) {
                  const courier = o.courier_name;
                  if (acc.includes(courier)) {
                    return acc;
                  } else {
                    acc = [...acc, courier];
                    return acc;
                  }
                }
                return acc;
              },
              ["All"]
            )
          );
        }
      } else {
        setOrders([]);
        setIsNoOrders(true);
      }
    }
    setLoading(false);
  };
  const handleChangeParts = (e) => {
    const val = parseInt(e.target.value);
    if (isNaN(val)) {
      setNumberOfParts(0);
    } else {
      setNumberOfParts(val);
    }
  };
  const handleDownload = () => {
    if (selectedCourier === "All") {
      toast.error("Select a courier");
    } else {
      // split first
      const splitPayload = splitArray(tableData, numberOfParts);
      let excelArr = [];
      for (let i = 0; i < splitPayload.length; i++) {
        const dataSplit = splitPayload[i].map((o) => ({
          ...o,
          fulfillment_id: o.fulfillment_id.toString(),
        }));
        //   create manifest
        createPDFforManifestForShiprocket(dataSplit, selectedCourier);
        //   create excel
        const xbData = createXLforDelhivery(dataSplit); // This just console logs the JSON. We need to make csv and download.
        excelArr.push(xbData);
      }
      setExcelData(excelArr);
    }
  };
  return (
    <div>
      <div>
        <h3>Shiprocket Bulk Orders</h3>
        <Stack direction={"row"} gap={2} my={2}>
          <TextField
            type="date"
            size="medium"
            variant="standard"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            label="processed date"
          />
          <Button variant="contained" onClick={handleSearch} disabled={loading}>
            Search
          </Button>
        </Stack>
        {isNoOrders && <h3>No orders found</h3>}
        {orders.length > 0 && (
          <Box>
            <OrderReport orders={orders} />
            <Filter
              orders={orders}
              setTableData={setTableData}
              statuses={statuses}
              comments={comments}
              couriers={couriers}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedComment={selectedComment}
              setSelectedComment={setSelectedComment}
              selectedCourier={selectedCourier}
              setSelectedCourier={setSelectedCourier}
              selectedProcessed={selectedProcessed}
              setSelectedProcessed={setSelectedProcessed}
            />
            <Box component={Paper} my={1} py={2} px={2}>
              <Stack direction={"row"} gap={2}>
                <TextField
                  type="text"
                  value={numberOfParts}
                  onChange={handleChangeParts}
                  variant="outlined"
                  label="Parts"
                  size="small"
                />
                <Button variant="outlined" onClick={handleDownload}>
                  Download
                </Button>
              </Stack>
            </Box>

            {tableData.length > 0 ? (
              <ShiprocketOrderTable tableData={tableData} />
            ) : (
              <h3>Data Not Found</h3>
            )}
          </Box>
        )}
      </div>
      {/* excel download */}
      {excelData.map((ed, i) => (
        <CSVLink
          key={i}
          data={ed}
          id={`csv-link-${i}`}
          // ref={csvLink}
          // target="_blank"
          filename={`${selectedCourier} ${i}`}
        />
      ))}
    </div>
  );
};

export default ShiprocketOrders;
