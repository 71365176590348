import React, { useEffect, useState } from "react";
import { Paper, Skeleton, Stack } from "@mui/material";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import FabricList from "./modifyMaterial/FabricList";
import ColorList from "./modifyMaterial/ColorList";
import AddMaterial from "./modifyMaterial/AddMaterial";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialColorList,
  getMaterialFabricList,
} from "../../actions/materialActions";
import MaterialList from "./modifyMaterial/MaterialList";
import UpdateMaterial from "./UpdateMaterial";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ModifyMaterials = () => {
  const [value, setValue] = useState(0);
  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Paper sx={{ marginBottom: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Fabric" {...a11yProps(0)} />
          <Tab label="Color" {...a11yProps(1)} />
          <Tab label="Material" {...a11yProps(2)} />
          <Tab label="Add Material" {...a11yProps(3)} />
          <Tab label="Update Material" {...a11yProps(4)} />
        </Tabs>
      </Paper>
      {!materialFabricList.length > 0 && !materialColorList.length > 0 ? (
        <Paper sx={{ width: 700, p: 5 }}>
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            <Skeleton variant="rectangular" width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} />
          </Stack>
        </Paper>
      ) : (
        <Paper>
          <TabPanel value={value} index={0}>
            <FabricList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ColorList />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <MaterialList />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AddMaterial />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UpdateMaterial />
          </TabPanel>
        </Paper>
      )}
    </div>
  );
};

export default ModifyMaterials;
