import * as React from "react";
import { useState, useEffect } from "react";
import {
  cuttingVendors,
  finishingVendors,
  getSizesByType,
  materialColorArray,
  materialFabricArray,
  materialSoleFabricArray,
  materialSolePlaceArray,
  stitchingVendors,
} from "../../utils/helperArrays";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  TextField,
  MenuItem,
  Avatar,
  Chip,
  Divider,
  Button,
  Fade,
  useScrollTrigger,
  Fab,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Stack } from "@mui/system";
import UnfulfilledPOSection from "./UnfulfilledPOSection";
import ProcessedQntyModal from "../poWidgets/ProcessedQnty/ProcessedQntyModal";
import UnfulfilledPONote from "./UnfulfilledPONote";
import { generateUnfulfilledPOExcel } from "../../utils/excelUtils";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
const stockFilterArr = ["none", "greater", "smaller", "between"];

const materialFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialSoleFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialSoleFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialColorList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialColorArray.sort((a, b) => a.name.localeCompare(b.name)),
];

const materialSolePlaceList = [
  { code: "All", name: "All" },
  ...materialSolePlaceArray.sort((a, b) => a.name.localeCompare(b.name)),
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function Row({ row, sizes, setSelectedProcessedQnty, handleOpenModal }) {
  const [open, setOpen] = React.useState(false);

  const {
    processedQnty,
    netStockPerSize,
    forcastedSalePerSize,
    poCovered,
    totalNetStock,
    totalForcastedSale,
    totalPOCovered,
    POPerDay,
    last15DaysSales,
    totalSales,
    avgSale,
    totalLateUnfulfilled,
    last7DaysSales,
    total7DaySales,
    last30DaysSales,
    total30DaySales,
    POPerDaySizeWise,
    minimumPOPerDay,
    totalPOPerDaySizeWise,
    cuttingAssignedVendors,
    stitchingAssignedVendors,
    finishingAssignedVendors,
  } = row;
  //
  const totalStock = Object.values(row.inventoryStock).reduce(
    (x, sum) => x + sum,
    0
  );

  // fabric consumption
  //  consumption :- unfulfilled - (stock + processing + RTO)
  // (consumption * material weight)/20 for kg
  // (consumption * material weight) for metre
  const isCutting =
    Object.values(processedQnty.cutting).reduce((acc, o) => acc + o, 0) > 0;
  const isStitching =
    Object.values(processedQnty.stitching).reduce((acc, o) => acc + o, 0) > 0;
  const isFinishing =
    Object.values(processedQnty.finishing).reduce((acc, o) => acc + o, 0) > 0;

  const totalCutting = Object.values(processedQnty.cutting).reduce(
    (acc, o) => acc + o,
    0
  );
  const totalStitching = Object.values(processedQnty.stitching).reduce(
    (acc, o) => acc + o,
    0
  );
  const totalFinishing = Object.values(processedQnty.finishing).reduce(
    (acc, o) => acc + o,
    0
  );

  const consumption =
    parseInt(row.total) -
    (parseInt(totalStock) + parseInt(processedQnty.total));
  const material = row.material.map((m) => {
    if (m.materialFormula && m.materialWeight) {
      const materialWeight = parseFloat(m.materialWeight);
      const value = Math.max(consumption, 0);
      const fabricReq =
        m.materialFormula === "IM"
          ? value * materialWeight
          : (value * materialWeight) / 20;
      return {
        ...m,
        fabricReq,
      };
    } else {
      return {
        ...m,
        fabricReq: 0,
      };
    }
  });
  let shopifyStock = {};
  if (row.stock[0].length > 0) {
    const mapped = row.stock[0].map((item) => ({
      [item.size]: item.stock,
    }));
    shopifyStock = Object.assign({}, ...mapped);
  }
  const totalShopifyInventory = row.stock[0].reduce(
    (sum, item) => item.stock + sum,
    0
  );
  console.log("row", row);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Stack
            direction={"column"}
            gap={2}
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <p>{row.price}</p>
            <Avatar
              src={row.src}
              sx={{ width: 100, height: 100 }}
              variant="square"
            />
            <div>
              {material.map((m, i) => (
                <p key={i} style={{ textAlign: "center" }}>{`${m.type}${
                  m.place ? `(${m.place})` : ""
                } ${m.color} `}</p>
              ))}
            </div>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={"column"}>
            <div>{row.sku}</div>
            {row.productAge <= 45 && (
              <div style={{ color: "red", fontWeight: "bold" }}>New</div>
            )}
          </Stack>
        </TableCell>
        <TableCell>{avgSale}</TableCell>
        <TableCell>{totalLateUnfulfilled}</TableCell>
        <TableCell>
          <div>
            {material.map((m, i) => (
              <p key={i}>
                {String(parseFloat(m.fabricReq).toFixed(2))}{" "}
                {m.materialFormula &&
                  (m.materialFormula === "IM" ? "metre" : "roll")}
              </p>
            ))}
          </div>
        </TableCell>
        <TableCell>{minimumPOPerDay} days</TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "140px",
            }}
          >
            <p style={{ background: "indianred", padding: "5px" }}>
              Unfulfilled
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              Inventory
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>PO</p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              Processing
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>RTO</p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              NetStock
            </p>
            <p style={{ background: "lightblue", padding: "5px" }}>
              15-21 days sale
            </p>
            <p
              style={{
                background: "lightblue",
                padding: "5px",
              }}
            >
              8-14 days sale
            </p>
            <p style={{ background: "lightblue", padding: "5px" }}>
              0-7 days sale
            </p>
            <p style={{ background: "beige", padding: "5px" }}>
              Forcasted Sale
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              Forcast (15 days)
            </p>
            <p style={{ background: "beige", padding: "5px" }}>
              Shopify Inventory
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              minimum day
            </p>
          </div>
        </TableCell>
        {sizes.map((i, k) => (
          <TableCell key={k}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  background: "indianred",
                  paddingTop: "5px",
                  padding: "5px",
                }}
              >
                {row.sizes[i] ? row.sizes[i] : 0}
              </p>
              <p
                style={{
                  background: "yellowgreen",
                  padding: "5px",
                }}
              >
                {row.inventoryStock[i] ? row.inventoryStock[i] : 0}
              </p>
              <p style={{ background: "yellowgreen", padding: "5px" }}>
                {processedQnty.PO[i]}
              </p>
              <p style={{ background: "yellowgreen", padding: "5px" }}>
                {processedQnty.totalPerSize[i]}
              </p>
              <p style={{ background: "yellowgreen", padding: "5px" }}>
                {row.RTO && row.RTO[i] ? row.RTO[i] : 0}
              </p>
              <p
                style={{
                  background: "yellowgreen",
                  padding: "5px",
                  color: `${
                    netStockPerSize[i] && netStockPerSize[i] < 0
                      ? "red"
                      : "black"
                  }`,
                }}
              >
                {netStockPerSize[i] ? netStockPerSize[i] : 0}
              </p>
              <p style={{ background: "lightblue", padding: "5px" }}>
                {last30DaysSales[i] ? last30DaysSales[i] : 0}
              </p>
              <p
                style={{
                  background: "lightblue",
                  padding: "5px",
                }}
              >
                {last15DaysSales[i] ? last15DaysSales[i] : 0}
              </p>
              <p style={{ background: "lightblue", padding: "5px" }}>
                {last7DaysSales[i] ? last7DaysSales[i] : 0}
              </p>
              <p style={{ background: "beige", padding: "5px" }}>
                {forcastedSalePerSize[i] ? forcastedSalePerSize[i] : 0}
              </p>
              <p style={{ background: "yellowgreen", padding: "5px" }}>
                {poCovered[i] ? poCovered[i] : 0}
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "5px",
                }}
              >
                {shopifyStock[i] ? shopifyStock[i] : 0}
              </p>
              <p style={{ background: "yellowgreen", padding: "5px" }}>
                {POPerDaySizeWise[i] ? POPerDaySizeWise[i] : 0}
              </p>
            </div>
          </TableCell>
        ))}
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "70px",
            }}
          >
            <p
              style={{
                background: "indianred",
                padding: "5px",
              }}
            >
              {row.total}
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              {totalStock}
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              {processedQnty.totalPO}
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              {processedQnty.total}
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>0</p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              {String(totalNetStock)}
            </p>
            <p style={{ background: "lightblue", padding: "5px" }}>
              {String(total30DaySales)}
            </p>
            <p
              style={{
                background: "lightblue",
                padding: "5px",
              }}
            >
              {String(totalSales)}
            </p>
            <p style={{ background: "lightblue", padding: "5px" }}>
              {String(total7DaySales)}
            </p>
            <p style={{ background: "beige", padding: "5px" }}>
              {String(totalForcastedSale)}
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>
              {String(totalPOCovered)}
            </p>
            <p
              style={{
                background: "beige",
                padding: "5px",
              }}
            >
              {totalShopifyInventory}
            </p>
            <p style={{ background: "yellowgreen", padding: "5px" }}>#</p>
          </div>
        </TableCell>
        <TableCell>
          {" "}
          <Stack
            direction={"column"}
            sx={{
              justifyContent: "space-between",
            }}
            gap={7}
          >
            <p>{String(POPerDay)} days</p>
            {/* <div style={{ minWidth: "100px" }}>
              {isCutting && <p>Cutting-{totalCutting}</p>}
              {isStitching && <p>Stitching-{totalStitching}</p>}
              {isFinishing && <p>Finishing-{totalFinishing}</p>}
            </div> */}
            <p>{row.orderId.length} Order</p>
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack direction={"column"} gap={9} height={"100%"}>
            <Stack direction={"column"} gap={1}>
              {cuttingAssignedVendors.length > 0 && (
                <div>
                  <h3>Cutting</h3>
                  {cuttingAssignedVendors.map((v, i) => (
                    <p key={i}>
                      {v.name}-{v.total}
                    </p>
                  ))}
                </div>
              )}
              {stitchingAssignedVendors.length > 0 && (
                <div>
                  <h3>Stitching</h3>
                  {stitchingAssignedVendors.map((v, i) => (
                    <p key={i}>
                      {v.name}-{v.total}
                    </p>
                  ))}
                </div>
              )}
              {finishingAssignedVendors.length > 0 && (
                <div>
                  <h3>Finishing</h3>
                  {finishingAssignedVendors.map((v, i) => (
                    <p key={i}>
                      {v.name}-{v.total}
                    </p>
                  ))}
                </div>
              )}
            </Stack>
            <div>
              <UnfulfilledPONote processedQnty={processedQnty} />
              {processedQnty && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedProcessedQnty(processedQnty);
                    handleOpenModal();
                  }}
                >
                  Process
                </Button>
              )}
            </div>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>All Orders</h3>
              <Stack direction={"row"} sx={{ justifyContent: "space-around" }}>
                {sizes.map((s, i) => (
                  <React.Fragment key={i}>
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "center",
                        width: "100%",
                        margin: "0 8px",
                      }}
                    >
                      <Box
                        sx={{
                          background: "rgba(153,14,199,0.3)",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "25px",
                            margin: "2px 0",
                          }}
                        >
                          {s}
                        </h3>
                      </Box>
                      <Divider flexItem />
                      <Stack
                        direction={"row"}
                        sx={{
                          flexWrap: "wrap",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {(row.orderWithSizes[s] || []).map((o, i) => (
                          <Chip
                            key={i}
                            variant="filled"
                            label={o}
                            style={{
                              // width: "40%",
                              margin: "5px 1px",
                              marginLeft: "0",
                              fontSize: "0.8rem",
                              color: "limegreen",
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function UnfulfilledPoTable(props) {
  const { tableData, productType, categoryTypes } = props;
  const [data, setData] = useState(
    tableData.sort((a, b) => a.POPerDay - b.POPerDay)
  );
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryType, setCategoryType] = useState("");
  // material states
  const [materialColor, setMaterialColor] = useState("All");
  const [materialFabric, setMaterialFabric] = useState("All");
  const [fabricArr, setFabricARR] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("All");
  //
  const [stockFilterOption, setStockFilterOption] = useState("none");
  const [isStockGreaterThan, setIsStockGreaterThan] = useState(0);
  const [isStockLesserThan, setIsStockLesserThan] = useState(0);
  const [sortBy, setSortBy] = useState("days");
  const [filteredData, setFilteredData] = useState([]);
  const [stockSize, setStockSize] = useState("Finite");
  const [productAge, setProductAge] = useState("All");
  const [desc, setDesc] = useState(false);
  const [selectedProcessedQnty, setSelectedProcessedQnty] = useState({});
  const [totalLateUnfulfilled, setTotalLateUnfulfilled] = useState(0);

  const [selectedCuttingVendor, setSelectedCuttingVendor] = useState("All");
  const [selectedStitchingVendor, setSelectedStitchingVendor] = useState("All");
  const [selectedFinishingVendor, setSelectedFinishingVendor] = useState("All");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (Object.keys(selectedProcessedQnty).length > 0) {
      const found = tableData.find(
        (t) => t.sku === selectedProcessedQnty.barcode
      );
      if (found) {
        setSelectedProcessedQnty(found.processedQnty);
      }
    }
  }, [tableData]);
  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData([...tableData].sort((a, b) => a.POPerDay - b.POPerDay));
    setCategoryType("");
    setPage(0);
  }, [productType, tableData]);

  useEffect(() => {
    if (categoryType && productType) {
      // (p) => p.sku?.substring(0, 2) === categoryType
      const filterTableData = tableData.filter((p) => {
        if (p.sku?.includes(categoryType)) return p;
      });
      if (filterTableData.length > 0) {
        const newData = [...filterTableData].reverse();
        setData(newData);
      } else {
        setData([]);
      }
    } else {
      setData([...tableData].sort((a, b) => a.POPerDay - b.POPerDay));
    }
    setPage(0);
  }, [categoryType, categoryTypes, productType, tableData]);

  // set fabric array
  useEffect(() => {
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricARR(materialSoleFabricList);
    } else {
      setFabricARR(materialFabricList);
    }
  }, [productType]);

  // filter
  useEffect(() => {
    // map total shopify stock
    const ordersWithTotalShopifyStock = data.map((d) => ({
      ...d,
      totalShopifyStock: d.stock[0].reduce((acc, d) => acc + d.stock, 0),
    }));
    // finite infinite filter
    const finiteInfiniteFiltered = ordersWithTotalShopifyStock.filter(
      (d) =>
        stockSize === "All" ||
        (stockSize === "Finite" && d.totalShopifyStock < 600) ||
        (stockSize === "Infinite" && d.totalShopifyStock >= 600)
    );
    // product age
    const productAgeFiltered = finiteInfiniteFiltered.filter(
      (d) =>
        productAge === "All" ||
        (productAge === ">30" && d.productAge > 30) ||
        (productAge === "<30" && d.productAge <= 30)
    );

    // vendors
    const cuttingVendorsFiltered = productAgeFiltered.filter(
      (p) =>
        selectedCuttingVendor === "All" ||
        !!p.cuttingAssignedVendors.find((v) => v.code === selectedCuttingVendor)
    );
    const stitchingVendorsFiltered = cuttingVendorsFiltered.filter(
      (p) =>
        selectedStitchingVendor === "All" ||
        !!p.stitchingAssignedVendors.find(
          (v) => v.code === selectedStitchingVendor
        )
    );
    const finishingVendorsFiltered = stitchingVendorsFiltered.filter(
      (p) =>
        selectedFinishingVendor === "All" ||
        !!p.finishingAssignedVendors.find(
          (v) => v.code === selectedFinishingVendor
        )
    );
    // shopify stock
    const shopifyStockFiltered = finishingVendorsFiltered.filter((p) => {
      const totalShopifyStock = p.totalShopifyStock;
      return (
        stockFilterOption === "none" ||
        (stockFilterOption === "greater" &&
          totalShopifyStock >= isStockGreaterThan) ||
        (stockFilterOption === "smaller" &&
          totalShopifyStock <= isStockLesserThan) ||
        (stockFilterOption === "between" &&
          totalShopifyStock >= isStockGreaterThan &&
          totalShopifyStock <= isStockLesserThan)
      );
    });
    const materialFiltered =
      materialColor === "No material" || materialFabric === "No material"
        ? shopifyStockFiltered.filter((d) => d.material.length === 0)
        : shopifyStockFiltered.filter(
            (d) =>
              (d.material.some((m) => m.color === materialColor) ||
                materialColor === "All") &&
              (d.material.some((m) => m.type === materialFabric) ||
                materialFabric === "All") &&
              (d.material.some((m) => m.place === materialPlace) ||
                materialPlace === "All")
          );
    if (sortBy === "unfulfilled") {
      const sortedData = materialFiltered.sort((a, b) =>
        desc
          ? Number(b.total) - Number(a.total)
          : Number(a.total) - Number(b.total)
      );
      setFilteredData(sortedData);
    } else if (sortBy === "days") {
      const sortedData = materialFiltered.sort((a, b) =>
        desc ? b.POPerDay - a.POPerDay : a.POPerDay - b.POPerDay
      );
      setFilteredData(sortedData);
    } else if (sortBy === "avg sale") {
      const sortedData = materialFiltered.sort((a, b) =>
        desc ? b.avgSale - a.avgSale : a.avgSale - b.avgSale
      );
      setFilteredData(sortedData);
    } else if (sortBy === "min days") {
      const sortedData = materialFiltered.sort((a, b) =>
        desc
          ? b.minimumPOPerDay - a.minimumPOPerDay
          : a.minimumPOPerDay - b.minimumPOPerDay
      );
      setFilteredData(sortedData);
    } else {
      const sortedData = materialFiltered.sort((a, b) =>
        desc
          ? b.totalLateUnfulfilled - a.totalLateUnfulfilled
          : a.totalLateUnfulfilled - b.totalLateUnfulfilled
      );
      setFilteredData(sortedData);
    }
    // setFilteredData(filtered);
  }, [
    data,
    materialFabric,
    materialColor,
    materialPlace,
    stockFilterOption,
    isStockGreaterThan,
    isStockLesserThan,
    stockSize,
    productAge,
    sortBy,
    desc,
    selectedCuttingVendor,
    selectedFinishingVendor,
    selectedStitchingVendor,
  ]);

  const showStockNumberFilter = () => {
    if (stockFilterOption === "none") {
      return <></>;
    } else if (stockFilterOption === "greater") {
      return (
        <TextField
          type="number"
          variant="filled"
          size="small"
          value={isStockGreaterThan}
          onChange={(e) => setIsStockGreaterThan(e.target.value)}
        />
      );
    } else if (stockFilterOption === "smaller") {
      return (
        <TextField
          type="number"
          variant="filled"
          size="small"
          value={isStockLesserThan}
          onChange={(e) => setIsStockLesserThan(e.target.value)}
        />
      );
    } else {
      return (
        <React.Fragment>
          <TextField
            type="number"
            variant="filled"
            size="small"
            value={isStockGreaterThan}
            onChange={(e) => setIsStockGreaterThan(e.target.value)}
          />
          <h3>-to-</h3>
          <TextField
            type="number"
            variant="filled"
            size="small"
            value={isStockLesserThan}
            onChange={(e) => setIsStockLesserThan(e.target.value)}
          />
        </React.Fragment>
      );
    }
  };
  const handleExcelDownload = () => {
    const excelData = filteredData.map((d) => ({
      sku: d.sku,
      PO: d.processedQnty.PO,
      cutting: d.processedQnty.cutting,
      stitching: d.processedQnty.stitching,
      finishing: d.processedQnty.finishing,
      unfulfilled: d.sizes,
      stock: d.inventoryStock,
    }));
    generateUnfulfilledPOExcel(excelData, sizes);
  };
  useEffect(() => {
    if (filteredData.length > 0) {
      const val = filteredData.reduce(
        (acc, d) => acc + d.totalLateUnfulfilled,
        0
      );
      setTotalLateUnfulfilled(val);
    }
  }, [filteredData]);
  return (
    <>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack direction={"row"} gap={2}>
          {/* category filter */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Product Category"
            value={categoryType}
            onChange={(e) => setCategoryType(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {categoryTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {/* material filter */}
          <TextField
            style={{ width: "200px" }}
            size="small"
            select
            label="Select Material Fabric"
            value={materialFabric}
            onChange={(e) => setMaterialFabric(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {fabricArr.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* color */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Select Material Color"
            value={materialColor}
            onChange={(e) => setMaterialColor(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {materialColorList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* material place */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Select Place"
            value={materialPlace}
            onChange={(e) => setMaterialPlace(e.target.value)}
            variant="filled"
            className="select"
          >
            {materialSolePlaceList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* cutting */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Cutting Vendor"
            value={selectedCuttingVendor}
            onChange={(e) => setSelectedCuttingVendor(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {[{ code: "All", name: "All" }, ...cuttingVendors].map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* stitching */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Stitching Vendor"
            value={selectedStitchingVendor}
            onChange={(e) => setSelectedStitchingVendor(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {[{ code: "All", name: "All" }, ...stitchingVendors].map(
              (option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>
          {/* finishing */}
          <TextField
            size="small"
            style={{ width: "200px" }}
            select
            label="Finishing Vendor"
            value={selectedFinishingVendor}
            onChange={(e) => setSelectedFinishingVendor(e.target.value)}
            variant="filled"
            className="select"
            fullWidth
          >
            {[{ code: "All", name: "All" }, ...finishingVendors].map(
              (option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              )
            )}
          </TextField>
        </Stack>
        <br />
        {/* stock filter */}
        <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
          <TextField
            style={{ width: "200px" }}
            select
            label="Filter"
            value={stockFilterOption}
            onChange={(e) => setStockFilterOption(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {stockFilterArr.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {showStockNumberFilter()}
          <TextField
            style={{ width: "200px" }}
            select
            label="Sort By"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {[
              "unfulfilled",
              "days",
              "avg sale",
              "unfulfilled > 8 days",
              "min days",
            ].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* Replace shopify stock to inventory stock */}
          {/* filter infinite finite */}
          {/* finite infinte stock filter */}
          <TextField
            style={{ width: "200px" }}
            select
            label="Stock size"
            value={stockSize}
            onChange={(e) => setStockSize(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {["Finite", "Infinite"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* product age <30 ,  >30 */}
          <TextField
            style={{ width: "200px" }}
            select
            label="Product Age"
            value={productAge}
            onChange={(e) => setProductAge(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {["All", ">30", "<30"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <br />
        <Stack direction={"row"} gap={2}>
          <CSVLink
            data={filteredData.map((d) => ({
              sku: d.sku,
              ...d.netStockPerSize,
              total: d.totalNetStock,
            }))}
            filename={`netstock.csv`}
          >
            <Button sx={{ mx: "10px" }} variant="contained">
              Download netStock
            </Button>
          </CSVLink>
          <Button variant={"contained"} onClick={handleExcelDownload}>
            Download Excel
          </Button>
        </Stack>
      </Paper>
      <div id="back-to-top-anchor"></div>
      <br />
      <UnfulfilledPOSection setStockSize={setStockSize} setPage={setPage} />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell
                onClick={() => {
                  if (sortBy !== "avg sale") {
                    setSortBy("avg sale");
                  }
                  setDesc(!desc);
                }}
              >
                <div style={{ display: "flex" }}>
                  Avg sale
                  <div
                    style={{ color: sortBy === "avg sale" ? "red" : "black" }}
                  >
                    {desc ? <ArrowDownward /> : <ArrowUpward />}
                  </div>
                </div>
              </TableCell>
              <TableCell
                onClick={() => {
                  if (sortBy !== "unfulfilled > 8 days") {
                    setSortBy("unfulfilled > 8 days");
                  }
                  setDesc(!desc);
                }}
              >
                <div style={{ display: "flex" }}>
                  <Stack
                    direction={"row"}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>late unfulfilled</p>
                    <p>({totalLateUnfulfilled})</p>
                  </Stack>

                  <div
                    style={{
                      color:
                        sortBy === "unfulfilled > 8 days" ? "red" : "black",
                    }}
                  >
                    {desc ? <ArrowDownward /> : <ArrowUpward />}
                  </div>
                </div>
              </TableCell>
              <TableCell>Fabric Req</TableCell>
              <TableCell
                onClick={() => {
                  if (sortBy !== "min days") {
                    setSortBy("min days");
                  }
                  setDesc(!desc);
                }}
              >
                <div style={{ display: "flex" }}>
                  Min day{" "}
                  <div
                    style={{
                      color: sortBy === "min days" ? "red" : "black",
                    }}
                  >
                    {desc ? <ArrowDownward /> : <ArrowUpward />}
                  </div>
                </div>
              </TableCell>
              <TableCell></TableCell>
              {sizes.map((i, k) => (
                <TableCell key={k}>Size : {i.toString()}</TableCell>
              ))}
              <TableCell
                onClick={() => {
                  if (sortBy !== "unfulfilled") {
                    setSortBy("unfulfilled");
                  }
                  setDesc(!desc);
                }}
              >
                <div style={{ display: "flex" }}>
                  Total
                  <div
                    style={{
                      color: sortBy === "unfulfilled" ? "red" : "black",
                    }}
                  >
                    {desc ? <ArrowDownward /> : <ArrowUpward />}
                  </div>
                </div>
              </TableCell>
              <TableCell
                onClick={() => {
                  if (sortBy !== "days") {
                    setSortBy("days");
                  }
                  setDesc(!desc);
                }}
              >
                <div style={{ display: "flex" }}>
                  Order IDs
                  <div style={{ color: sortBy === "days" ? "red" : "black" }}>
                    {desc ? <ArrowDownward /> : <ArrowUpward />}
                  </div>
                </div>
              </TableCell>
              {/* <TableCell>Inventory Stock</TableCell> */}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                handleOpenModal={handleOpenModal}
                sizes={sizes}
                setSelectedProcessedQnty={setSelectedProcessedQnty}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <ScrollTop {...props}>
          <Fab size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </TableContainer>
      {Object.keys(selectedProcessedQnty).length > 0 && (
        <ProcessedQntyModal
          open={openModal}
          setOpen={setOpenModal}
          handleOpen={handleOpenModal}
          setSelectedProcessedQnty={setSelectedProcessedQnty}
          processedQnty={selectedProcessedQnty}
          sizes={sizes}
        />
      )}
    </>
  );
}
