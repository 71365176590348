import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Table,
  Collapse,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  IconButton,
  Avatar,
  Box,
  TextField,
  MenuItem,
  Stack,
  Checkbox,
  Button,
  CircularProgress,
  Typography,
  Chip,
} from "@mui/material";

import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  productTypes,
  categoryTypes,
  getSizesByType,
  materialColorArray,
  materialFabricArray,
  materialSoleFabricArray,
  materialSolePlaceArray,
} from "../../utils/helperArrays";
import {
  changeAvailableQuantity,
  changeProductsAfterAvailableQntyChange,
} from "../../actions/productsActions";

const materialFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialSoleFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialSoleFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialColorList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialColorArray.sort((a, b) => a.name.localeCompare(b.name)),
];

const materialSolePlaceList = [
  { code: "All", name: "All" },
  ...materialSolePlaceArray.sort((a, b) => a.name.localeCompare(b.name)),
];

const NewProductsSalesTable = ({ sales }) => {
  //
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);

  const [tableData, setTableData] = useState([]);
  const [productType, setProductType] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [categoryTypesArray, setCategoryTypesArray] = useState([]);
  const [productVariantList, setProductVariantList] = useState([]);

  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      // console.log(temp)
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  useEffect(() => {
    //Create the Tabledata
    if (sales?.length === 0) {
      //RESET FILTER
      setTableData([]);
    }
    if (productVariantList.length > 0 && inventoryStock.length > 0) {
      const data = sales.map((p) => {
        const imageObj = inventoryStock.find((item) => item.SKU === p.sku);

        let src = null;
        if (imageObj) {
          src = imageObj.imageUrl;
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
        }

        let variantArr = [];
        const variantObj = productVariantList.filter(
          (item) => item.sku === p.sku
        );
        variantArr.push(
          variantObj.map((x) => ({
            size: x.title,
            stock: x.stock ? x.stock : 0,
          }))
        );
        const itemIdList = variantObj.reduce((acc, x) => {
          acc[x.title] = x.inventory_item_id;
          return acc;
        }, {});
        let inventoryArr = [];
        const inventoryObj = inventoryStock.filter(
          (item) => item.SKU === p.sku
        );
        inventoryArr.push(
          inventoryObj.map((x) => ({
            size: x.size,
            stock: x.count ? x.count : 0,
          }))
        );
        const inventoryObjHavingDate = inventoryObj.find((i) => "date" in i);

        return {
          ...p,
          tags: "",
          src,
          publishedAt: inventoryObjHavingDate
            ? changeDateFormat(new Date(inventoryObjHavingDate.date))
            : "Invalid Date",
          itemIds: itemIdList,
          type: imageObj?.type,
          material: imageObj && "material" in imageObj ? imageObj.material : [],
          stock: variantArr,
          inventoryStock: inventoryArr,
          totalShopifyStock: variantArr[0].reduce(
            (sum, item) => item.stock + sum,
            0
          ),
        };
      });

      setTableData(data);
    }
  }, [inventoryStock, productVariantList, sales]);

  useEffect(() => {
    if (tableData.length > 0 && productType) {
      const getCategories = categoryTypes(productType);
      const filtered = tableData.filter((item) => item.type === productType);
      setCategoryTypesArray(getCategories);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [productType, tableData]);

  return (
    <div>
      <br />
      <br />
      <div style={{ display: "flex" }}>
        {sales?.length > 0 && (
          <TextField
            style={{ width: "500px" }}
            select
            label="Select Product Type"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <br />
      {productType && filteredProducts.length > 0 ? (
        <SalesTable
          tableData={filteredProducts}
          categoryTypes={categoryTypesArray}
          productType={productType}
        />
      ) : (
        <>
          {productType && filteredProducts.length > 0 && (
            <h3>Products not found</h3>
          )}
        </>
      )}
    </div>
  );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const dispatch = useDispatch();

  console.log("row", row);
  // const totalInventory = row.stock[0].reduce(
  //   (sum, item) => item.stock + sum,
  //   0
  // );
  const handleChangeToFinite = async () => {
    setReplaceLoading(true);
    const available = Object.keys(row.itemIds).reduce((acc, s) => {
      const inventoryStock = row.inventoryStock[0];
      const obj = {
        size: s,
        id: row.itemIds[s],
        available:
          row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0) > 0
            ? row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0)
            : 0,
      };
      acc.push(obj);
      return acc;
    }, []);
    const data = await changeAvailableQuantity(available);
    if (data.status === 200) {
      dispatch(changeProductsAfterAvailableQntyChange(available));
    }
    setReplaceLoading(false);
  };

  const handleChangeToInfinite = async () => {
    setReplaceLoading(true);
    delete row.itemIds["Free Size (Fits XS to L)"];
    const available = Object.keys(row.itemIds).reduce((acc, s) => {
      const obj = {
        size: s,
        id: row.itemIds[s],
        available: 10000,
      };
      acc.push(obj);
      return acc;
    }, []);
    const data = await changeAvailableQuantity(available);
    if (data.status === 200) {
      dispatch(changeProductsAfterAvailableQntyChange(available));
    }
    setReplaceLoading(false);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Avatar
            src={row.src}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
        </TableCell>
        <TableCell>
          {new Date(row.publishedAt).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell>{row.price}</TableCell>
        <TableCell>{row.sku}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row["1"] ? row["1"].total : 0}</TableCell>
        <TableCell>{row["2"] ? row["2"].total : 0}</TableCell>
        <TableCell>{row["3"] ? row["3"].total : 0}</TableCell>
        <TableCell>{row["4"] ? row["4"].total : 0}</TableCell>
        <TableCell>{row["5"] ? row["5"].total : 0}</TableCell>
        <TableCell>{row.total}</TableCell>
        {row.material.length > 0 ? (
          <React.Fragment>
            <TableCell>
              <div>
                {row.material.map((m) => (
                  <p>{`${m.type}${m.place ? `(${m.place})` : ""} ${
                    m.color
                  } `}</p>
                ))}
              </div>
            </TableCell>
            <TableCell>
              <div>
                {row.material.map((m) => (
                  <p>{m.amount}</p>
                ))}
              </div>
            </TableCell>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TableCell>Not found</TableCell>
            <TableCell>Not found</TableCell>
          </React.Fragment>
        )}
        <TableCell>{row.totalShopifyStock}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {/* change to finite / change to infinite */}
          <Button
            variant="outlined"
            onClick={handleChangeToInfinite}
            size="small"
          >
            {replaceLoading ? <CircularProgress /> : "To Infinite"}
          </Button>

          <Box style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
          <Chip label={row.status} 
              style={{
                textAlign: "center",
                backgroundColor: row.status === "draft" ? "#ec0000" : "green", 
                color: "white", 
                border: '1px solid', 
                gap: "2px"
              }}
          />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>Shopify Inventory</h3>
              <div style={{ display: "flex" }}>
                {row.stock[0].map((i, k) => (
                  <div key={k} style={{ paddingRight: "30px" }}>
                    <div style={{ color: "red" }}>{i.size}</div>
                    <div>
                      <b>{i.stock}</b>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
            <Box sx={{ margin: 1 }}>
              <h3>Warehouse Inventory</h3>
              <div style={{ display: "flex" }}>
                {row.inventoryStock[0].map((i, k) => (
                  <div key={k} style={{ paddingRight: "30px" }}>
                    <div style={{ color: "red" }}>{i.size}</div>
                    <div>
                      <b>{i.stock}</b>
                    </div>
                  </div>
                ))}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function SalesTable({ tableData, productType, categoryTypes }) {
  const [data, setData] = useState(
    tableData.sort((a, b) => Number(a.total) - Number(b.total))
  );

  const [desc, setDesc] = useState(false);
  const [weekNumber, setWeekNumber] = useState("");
  const [weekdesc, setWeekDesc] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryType, setCategoryType] = useState("");

  const [materialColor, setMaterialColor] = useState("All");
  const [materialFabric, setMaterialFabric] = useState("All");
  const [fabricArr, setFabricARR] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("All");

  const [isStockFiltered, setIsStockFiltered] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData(
      [...tableData].sort((a, b) => Number(a.total) - Number(b.total)).reverse()
    );
    setCategoryType("");
  }, [productType, tableData]);

  useEffect(() => {
    if (desc) {
      const newData = [...tableData].reverse();
      setData(newData);
    } else {
      const newData = [...tableData];
      setData(newData);
    }
  }, [desc]);

  useEffect(() => {
    if (weekdesc && weekNumber !== "") {
      const toggleTriData = [...tableData]
        .sort(
          (a, b) =>
            Number(a[`${weekNumber}`]?.total || 0) -
            Number(b[`${weekNumber}`]?.total || 0)
        )
        .reverse();
      setData(toggleTriData);
    } else if (!weekdesc && weekNumber !== "") {
      const toggleTriData = [...tableData].sort(
        (a, b) =>
          Number(a[`${weekNumber}`]?.total || 0) -
          Number(b[`${weekNumber}`]?.total || 0)
      );
      setData(toggleTriData);
    }
  }, [weekdesc, weekNumber]);

  useEffect(() => {
    if (categoryType && productType) {
      const filterTableData = tableData.filter((p) =>
        p.sku?.includes(categoryType)
      );
      if (filterTableData.length > 0) {
        const newData = [...filterTableData].reverse();
        setData(newData);
      } else {
        setData([]);
      }
    } else {
      setData(
        [...tableData]
          .sort((a, b) => Number(a.total) - Number(b.total))
          .reverse()
      );
    }
    setPage(0);
  }, [categoryType, categoryTypes, productType]);

  useEffect(() => {
    const materialFiltered =
      materialColor === "No material" || materialFabric === "No material"
        ? data.filter((d) => d.material.length === 0)
        : data.filter(
            (d) =>
              (d.material.some((m) => m.color === materialColor) ||
                materialColor === "All") &&
              (d.material.some((m) => m.type === materialFabric) ||
                materialFabric === "All") &&
              (d.material.some((m) => m.place === materialPlace) ||
                materialPlace === "All")
          );
    const stockFiltered = isStockFiltered
      ? materialFiltered.filter((p) => p.totalShopifyStock < 10000)
      : materialFiltered;
    setFilteredData(stockFiltered);

    // if (materialColor === "No material" || materialFabric === "No material") {
    //   const filtered = data.filter((d) => d.material.length === 0);
    //   setFilteredData(filtered);
    // } else {
    //   const filtered = data.filter(
    //     (d) =>
    //       (d.material.some((m) => m.color === materialColor) ||
    //         materialColor === "All") &&
    //       (d.material.some((m) => m.type === materialFabric) ||
    //         materialFabric === "All") &&
    //       (d.material.some((m) => m.place === materialPlace) ||
    //         materialPlace === "All")
    //   );
    //   setFilteredData(filtered);
    // }
    // setFilteredData(filtered);
  }, [data, materialFabric, materialColor, materialPlace, isStockFiltered]);

  // set fabric array
  useEffect(() => {
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricARR(materialSoleFabricList);
    } else {
      setFabricARR(materialFabricList);
    }
  }, [productType]);
console.log(filteredData)
  return (
    <>
      <TextField
        style={{ width: "500px" }}
        select
        label="Select Product Category"
        value={categoryType}
        onChange={(e) => setCategoryType(e.target.value)}
        variant="outlined"
        className="select"
        fullWidth
      >
        {categoryTypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <br />
      {/* material filter */}
      <Stack direction={"row"} gap={3}>
        {/* fabric */}
        <TextField
          style={{ width: "500px" }}
          select
          label="Select Material Fabric"
          value={materialFabric}
          onChange={(e) => setMaterialFabric(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
        >
          {fabricArr.map((option) => (
            <MenuItem key={option.code} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {/* color */}
        <TextField
          style={{ width: "500px" }}
          select
          label="Select Material Color"
          value={materialColor}
          onChange={(e) => setMaterialColor(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
        >
          {materialColorList.map((option) => (
            <MenuItem key={option.code} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {/* material place */}
        <TextField
          style={{ width: "250px" }}
          select
          label="Select Place"
          value={materialPlace}
          onChange={(e) => setMaterialPlace(e.target.value)}
          variant="outlined"
          className="select"
        >
          {materialSolePlaceList.map((option) => (
            <MenuItem key={option.code} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Stack direction={"row"} gap={1} sx={{ alignItems: "center" }}>
        <h3
          style={{
            fontWeight: 400,
            color: "gray",
          }}
        >
          Remove products with shopify stock above 10,000
        </h3>
        <Checkbox
          checked={isStockFiltered}
          onChange={(e) => setIsStockFiltered(!isStockFiltered)}
        />
      </Stack>

      <br />
      <br />
      {!data.length > 0 && <h3>Products not found</h3>}
      {data.length > 0 && sizes.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Published At</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Name</TableCell>
                  {/* <TableCell>Week 1</TableCell> */}
                  <TableCell
                    onClick={() => {
                      setWeekNumber("1");
                      setWeekDesc(!weekdesc);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      0-3 days
                      <div>
                        {weekdesc ? <ArrowDownward /> : <ArrowUpward />}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setWeekNumber("2");
                      setWeekDesc(!weekdesc);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      3-6 days
                      <div>
                        {weekdesc ? <ArrowDownward /> : <ArrowUpward />}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setWeekNumber("3");
                      setWeekDesc(!weekdesc);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      6-9 days
                      <div>
                        {weekdesc ? <ArrowDownward /> : <ArrowUpward />}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setWeekNumber("4");
                      setWeekDesc(!weekdesc);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      9-12 days
                      <div>
                        {weekdesc ? <ArrowDownward /> : <ArrowUpward />}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setWeekNumber("5");
                      setWeekDesc(!weekdesc);
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      12-15 days
                      <div>
                        {weekdesc ? <ArrowDownward /> : <ArrowUpward />}
                      </div>
                    </div>
                  </TableCell>
                  {/* <TableCell>Week 2</TableCell>
                  <TableCell>Week 3</TableCell>
                  <TableCell>Week 4</TableCell> */}
                  <TableCell onClick={() => setDesc(!desc)}>
                    <div style={{ display: "flex" }}>
                      Total
                      <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
                    </div>
                  </TableCell>
                  <TableCell>material</TableCell>
                  <TableCell>material amt</TableCell>
                  <TableCell>Total Shopify Stock</TableCell>
                  <TableCell>Check Stock</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                  ? filteredData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredData
                ).map((row, index) => (
                  <Row key={index} row={row} />
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default NewProductsSalesTable;
