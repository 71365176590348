const initialState = {
    reconDetails: []
  };
  
  export const reconReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'fetchScanSingleDateSuccess':
        return {
          ...state,
          reconDetails: action.payload,
        };
      case 'submitAwbSuccess':
        return {
          ...state,
        };
        
      default:
        return state;
    }
  };
  