import React, { useEffect, useState } from "react";
import { AgCharts } from "ag-charts-react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Stack } from "@mui/material";
const DemoPaper = styled(Box)(({ theme }) => ({
  width: 500,
  height: 500,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const FabricAvailabilityPieChart = ({ materials }) => {
  const [options, setOptions] = useState({
    data: [],
    series: [
      {
        type: "pie",
        angleKey: "count",
        legendItemKey: "category",
        calloutLabelKey: "count",
        sectorLabelKey: "count",
      },
    ],
  });

  useEffect(() => {
    const data = materials.map((m) => ({
      category: `${m.fabricName} ${m.colorName}`,
      count: parseFloat(m.amt),
    }));
    console.log("checking", data, materials);
    setOptions((prevOptions) => ({
      ...prevOptions,
      data: data,
    }));
  }, [materials]);
  console.log("options", options);
  return (
    <Paper sx={{ my: 2 }}>
      <Stack
        direction={"column"}
        gap={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>FabricAvailabilityPieChart</h2>
        {materials.length === 0 ? (
          <h3>Not Available</h3>
        ) : (
          <DemoPaper>
            <AgCharts options={options} />
          </DemoPaper>
        )}
      </Stack>
    </Paper>
  );
};

export default FabricAvailabilityPieChart;
