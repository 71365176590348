import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

function ReceivedItems({ data }) {
  const result =
    data &&
    data.map((item) => ({
      fulfillment_id: "fulfillment_id" in item ? item.fulfillment_id : "N/A",
      created_at: "created_at" in item ? item.created_at : "N/A",
      updated_at: "updated_at" in item ? item.updated_at : "N/A",
      rcvd_items: "rcvd_items" in item ? item.rcvd_items : "N/A",
      type: "type" in item ? item.type : "N/A",
    }));

  return (
    <Box>
      {data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fullfillment ID</TableCell>
                <TableCell align="right">Create dAt</TableCell>
                <TableCell align="right">Updated At</TableCell>
                <TableCell align="right">line Items</TableCell>
                <TableCell align="right">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {result[0].fulfillment_id}
                </TableCell>
                <TableCell align="right">{result[0].created_at}</TableCell>
                <TableCell align="right">{result[0].updated_at}</TableCell>
                <TableCell
                  align="right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {Array.isArray(result[0].rcvd_items)
                    ? result[0].rcvd_items.map((item, i) => {
                        return <Chip key={i} label={item.sku} />;
                      })
                    : result[0].rcvd_items}
                </TableCell>
                <TableCell align="right">{result[0].type}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default ReceivedItems;
