import { Box, Button, Popover, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { editPONote } from "../../actions/POActions";

const UnfulfilledPONote = ({ processedQnty }) => {
  const { note } = processedQnty;
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState(note);
  const noteRef = useRef(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = () => {
    setAnchorEl(noteRef.current);
  };

  const handleClose = () => {
    setComment("");
    setAnchorEl(null);
  };

  const handleSave = () => {
    const payload = {
      sku: processedQnty.barcode,
      note: comment,
    };
    dispatch(editPONote(payload));
    setAnchorEl(null);
  };
  return (
    <div>
      <Box
        ref={noteRef}
        onClick={handleClick}
        sx={{
          cursor: "pointer",
        }}
      >
        {note ? (
          <Box
            sx={{
              border: "0.3px solid gray",
              borderRadius: "5px",
              padding: "3px",
              my: 1,
            }}
          >
            <h5>Note :</h5>
            {note}
          </Box>
        ) : (
          <Button>Add Note</Button>
        )}
      </Box>
      {/* custom comment */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Typography>Note</Typography>
          <br />
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            size="medium"
          />
          <br />
          <Button variant="contained" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

export default UnfulfilledPONote;
