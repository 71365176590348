import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Chip, CircularProgress, circularProgressClasses } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from "@mui/material/styles";
import { TableFooter, TablePagination, IconButton } from "@mui/material";
import ReconModal from "../ReconModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Last48hourssReconModal from "../Last48hourssReconModal";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <ArrowUpwardIcon />
        ) : (
          <ArrowDownwardIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ReconciliationTable = ({ data }) => {
  const [totalNumberDeclared, setTotalNumberDeclared] = useState(
    data?.orders?.filter((f) => "created_at" in f).length
  );
  const [totalNumberReceived, setTotalNumberReceived] = useState(
    data?.orders?.filter((f) => "processed_at" in f).length
  );
  const [totalNumberInProgress, setTotalNumberInProgress] = useState(
    data?.orders?.filter((f) => !("processed_at" in f)).length
  );
  const [totalNumberClaimedByDelhivery, setTotalNumberClaimedByDelhivery] =
    useState(data?.orders?.filter((f) => "rto_delivered_on" in f).length);

  const [rto_declared_within48,setRto_declared_within48] = useState("0");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTotalNumberDeclared(data?.orders?.filter((f) => "created_at" in f).length);
    setTotalNumberReceived(
      data?.orders?.filter((f) => "processed_at" in f).length
    );
    setTotalNumberInProgress(
      data?.orders?.filter((f) => !("processed_at" in f)).length
    );
    setTotalNumberClaimedByDelhivery(
      data?.orders?.filter((f) => "rto_delivered_on" in f).length
    );

    if(data?.orders.length > 0 ){
      const orders = data?.orders?.reduce((acc,i)=>{
        if(new Date(i.created_at) && new Date(i.pickedup_date)){
          return (new Date(i.created_at) - new Date(i.pickedup_date) < 172800000) ? acc + 1 : acc
        } else {
          return acc
        }
      },0)
  
      setRto_declared_within48(String(orders));
    }
  }, [data]);

  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [openModalLastTwoDays, setOpenModalLastTwoDays] = useState(false);
  const [f_id, setFId] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.orders.length) : 0;

  // Ensure that the page is within the valid range
  useEffect(() => {
    const maxPage = Math.max(0, Math.ceil(data.orders.length / rowsPerPage) - 1);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data.orders.length, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (f_id) => {
    setFId(f_id);
    setOpenModal(true);
  };

  const handleOpenModalLastTwoDays = () => {
    setLoading(true);
    setOpenModalLastTwoDays(true);
    setTimeout(() => setLoading(false), 1500);
  };

  useEffect(() => {
    setPage(0);
  }, [data]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data.orders].sort((a, b) => {
    const valueA = a[sortConfig.key] || "N/A";
    const valueB = b[sortConfig.key] || "N/A";
    const dateA = valueA === "N/A" ? Number.MAX_SAFE_INTEGER : new Date(valueA).getTime();
    const dateB = valueB === "N/A" ? Number.MAX_SAFE_INTEGER : new Date(valueB).getTime();

    if (sortConfig.direction === "asc") {
      return dateA - dateB;
    }
    return dateB - dateA;
  });

  return (
    <Box>
      {data && (
        <>
          <Box style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "8px",
          }}>
            <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 1.5,
              width: "25%",
              padding: 4,
              margin: "0 auto",
              boxShadow: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>
                Selected Date:{" "}
                <Chip
                  label={new Date(data?.day).toLocaleDateString("IN")}
                  style={{
                    textAlign: "center",
                    backgroundColor: data.mark ? "red" : "green",
                    color: "white",
                    border: "1px solid",
                  }}
                />
              </h5>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>Total Number of RTO Declared: {totalNumberDeclared}</h5>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>Total Number of RTO Received: {totalNumberReceived}</h5>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>
                Claimed Number of RTO delivered by delhivery:{" "}
                {totalNumberClaimedByDelhivery}
              </h5>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>Total Number of RTO In Progress: {totalNumberInProgress}</h5>
            </Box>
            </Paper>
            <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 1.5,
              width: "25%",
              padding: 4,
              margin: "0 auto",
              boxShadow: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>
                Selected Date:{" "}
                <Chip
                  label={new Date(data?.day).toLocaleDateString("IN")}
                  style={{
                    textAlign: "center",
                    backgroundColor: data.mark ? "red" : "green",
                    color: "white",
                    border: "1px solid",
                  }}
                />
              </h5>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TaskAltIcon sx={{ mr: 1 }} />
              <h5>Total Number of RTO Declared within 48 hours: {rto_declared_within48}</h5>
            </Box>

            {loading ? (
      <CircularProgress /> // Show CircularProgress while loading
    ) : (
      <Button onClick={handleOpenModalLastTwoDays}>See More</Button>
    )}
            </Paper>
          </Box>
          <Box pt={2}>
            {data.orders && data.orders.length > 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fullfillment ID</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">RTO Declared At</TableCell>
                      <TableCell align="center">
                       RTO Delivered On
                       <IconButton onClick={() => handleSort("rto_delivered_on")}>
                          {sortConfig.key === "rto_delivered_on" && sortConfig.direction === "asc" ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </IconButton>
                       </TableCell>
                       <TableCell align="center">
                        Warehouse Received At
                        <IconButton onClick={() => handleSort("processed_at")}>
                          {sortConfig.key === "processed_at" && sortConfig.direction === "asc" ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">Line Items</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : sortedData
                    ).map((row, i) => {
                      const hasProcessedAt = "processed_at" in row;

                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: hasProcessedAt ? "inherit" : "#e6e6e6",
                            border: "1.5px solid #f2f2f2",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.fulfillment_id}
                          </TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                          <TableCell align="center">
                            <Box>
                              {!row.created_at ? (
                                "N/A"
                              ) : (
                                <>
                                  <span>
                                    {new Date(row.created_at).toLocaleDateString("en-IN")}
                                  </span>
                                  <br />
                                  <span>
                                    {new Date(row.created_at).toLocaleTimeString("en-IN")}
                                  </span>
                                </>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box>
                              {!row.rto_delivered_on ? (
                                "N/A"
                              ) : (
                                <>
                                  <span>
                                    {new Date(row.rto_delivered_on).toLocaleDateString("en-IN")}
                                  </span>
                                  <br />
                                  <span>
                                    {new Date(row.rto_delivered_on).toLocaleTimeString("en-IN")}
                                  </span>
                                </>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box>
                              {!row.processed_at ? (
                                "N/A"
                              ) : (
                                <>
                                  <span>
                                    {new Date(row.processed_at).toLocaleDateString("en-IN")}
                                  </span>
                                  <br />
                                  <span>
                                    {new Date(row.processed_at).toLocaleTimeString("en-IN")}
                                  </span>
                                </>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {Array.isArray(row.line_items) ? (
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 2,
                                }}
                              >
                                {row.line_items.map((item, i) => (
                                  <Chip
                                    key={i}
                                    label={item.sku}
                                    onClick={() => handleOpenModal(row.fulfillment_id)}
                                    sx={{
                                      backgroundColor: "#65b3f7",
                                      color: "black",
                                    }}
                                  />
                                ))}
                              </Box>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        style={{ overflow: "inherit" }}
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={data.orders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
      )}
      <ReconModal open={openModal} f_id={f_id} setOpen={setOpenModal} />
      {data.orders && !loading && <Last48hourssReconModal open={openModalLastTwoDays} setOpen={setOpenModalLastTwoDays} data={data.orders}/>}
    </Box>
  );
};

export default ReconciliationTable;
