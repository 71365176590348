import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productTypes,
  getSizesByType,
  categoryTypes,
  materialColorArray,
  materialFabricArray,
  findMaterialId,
  materialSoleFabricArray,
  materialSolePlaceArray,
  accessories,
} from "../../utils/helperArrays";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TextField,
  MenuItem,
  Chip,
  Button,
  Container,
  Paper,
  Box,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Autocomplete,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { editInventoryProducts } from "../../actions/inventoryActions";
import { showFullScreenLoader } from "../../actions/loaderActions";

const EditProductsForm = () => {
  const dispatch = useDispatch();
  const { stock } = useSelector((state) => state.inventoryDetails);

  const [sku, setSku] = useState("");
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [productType, setProductType] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([]);

  const [materialType, setMaterialType] = useState("");
  const [materialColor, setMaterialColor] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");
  const [materialObj, setMaterialObj] = useState([]);
  const [openAddMaterial, setOpenAddMaterial] = useState(false);
  const [fabricArr, setFabricArr] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("");
  const [materialWeight, setMaterialWeight] = useState("");
  const [materialFormula, setMaterialFormula] = useState("");
  const [selectedAccessory, setSelectedAccessory] = useState();
  useEffect(() => {
    if (productType) {
      const categories = categoryTypes(productType);
      setCategoriesArray(categories);
    } else if (filteredProduct.length > 0) {
      setProductType(
        productTypes.filter((x) => x.value === filteredProduct[0].type)[0].value
      );
      setImageUrl(filteredProduct[0].imageUrl);
      console.log("filteredProduct", filteredProduct);
      setMaterialObj(
        !!filteredProduct[0].material ? filteredProduct[0].material : []
      );

      const categories = categoryTypes(filteredProduct[0].type);
      console.log("categories", categories, filteredProduct);
      setCategoriesArray(categories);
      setCategoryType(
        categories.filter((x) => x.label === filteredProduct[0].category)[0]
          .value
      );
      if (filteredProduct.accessory) {
        setSelectedAccessory(JSON.stringify(filteredProduct));
      }
    }
  }, [productType, filteredProduct]);

  const handleSearch = () => {
    const filteredSku = stock.filter((x) => x.SKU === sku);
    if (filteredSku.length > 0) {
      setProductType("");
      setCategoriesArray([]);
      setCategoryType("");
      setImageUrl("");
      setMaterialType("");
      setMaterialColor("");
      setMaterialAmount("");
      setMaterialObj([]);
      setOpenAddMaterial(false);
      setFilteredProduct(filteredSku);
    } else toast.error("This SKU doesn't exist");
  };

  const handleSubmit = () => {
    dispatch(showFullScreenLoader());
    const sizes = getSizesByType(productType);

    const newProduct = sizes.reduce((acc, i) => {
      const product = {
        SKU: sku,
        category: categoriesArray.filter((x) => x.value === categoryType)[0]
          .label,
        type: productType,
        imageUrl: imageUrl,
        material: materialObj,
        size: i,
        accessory: selectedAccessory ? JSON.parse(selectedAccessory) : {},
      };
      acc.push(product);
      return acc;
    }, []);
    console.log(newProduct);
    dispatch(editInventoryProducts(newProduct));
  };

  const handleMaterialObj = () => {
    // material object
    // add id
    const newObj = {
      type: materialType,
      color: materialColor,
      amount: materialAmount,
      name: String(materialType) + " " + String(materialColor),
      place: materialPlace,
      materialFormula,
      materialWeight,
      id: findMaterialId(materialType, materialColor),
    };
    setMaterialObj((prev) => [...prev, newObj]);
    setMaterialType("");
    setMaterialColor("");
    setMaterialAmount("");
    setMaterialWeight("");
    setMaterialFormula("");
    setOpenAddMaterial(false);
  };

  //   for autocomplete
  const getOptionLabel = (option) => {
    if (option) {
      return option.name;
    }
    return "";
  };

  useEffect(() => {
    console.log("product type", productType);
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricArr(materialSoleFabricArray);
    } else {
      setFabricArr(materialFabricArray);
    }
  }, [productType]);

  return (
    <Container>
      <br />
      <br />
      <Paper sx={{ margin: "auto", padding: "25px", width: "max-content" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              style={{ width: "500px" }}
              label="Enter A Product SKU"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <Button
              style={{ left: "10px" }}
              variant="contained"
              color="secondary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </div>
      </Paper>
      <br />
      <>
        {filteredProduct.length > 0 ? (
          <Paper sx={{ margin: "auto", padding: "25px" }}>
            <Box style={{ display: "flex" }}>
              <TextField
                style={{ width: "500px" }}
                select
                label="Product Type"
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                variant="outlined"
                className="select"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              >
                {productTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                style={{ width: "500px", left: "20px" }}
                select
                label="Category Type"
                value={categoryType}
                onChange={(e) => setCategoryType(e.target.value)}
                variant="outlined"
                className="select"
                size="small"
                // fullWidth
              >
                {categoriesArray.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <br />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={filteredProduct[0].imageUrl}
                sx={{
                  height: "200px",
                  width: "200px",
                }}
                variant="square"
              />
              <TextField
                style={{ width: "800px", left: "20px" }}
                label="Image Url"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                variant="outlined"
                className="select"
                size="small"
              />
            </Box>
            <br />
            <TextField
              style={{ width: "300px" }}
              select
              label="Select Accessories"
              value={selectedAccessory || ""}
              onChange={(e) => {
                setSelectedAccessory(e.target.value);
              }}
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className="select"
            >
              {accessories.map((option) => (
                <MenuItem key={option.id} value={JSON.stringify(option)}>
                  {option.zip}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <br />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <h4>Add Material Details</h4>
              <Box style={{ paddingLeft: "10px" }}>
                <AddIcon
                  onClick={() => setOpenAddMaterial((prev) => !prev)}
                  fontSize="large"
                  style={{
                    color: "white",
                    background: "black",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
            <Collapse in={openAddMaterial}>
              <Paper sx={{ bgcolor: "whitesmoke" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "20px",
                    gap: 2,
                  }}
                >
                  <TextField
                    style={{ width: "250px" }}
                    select
                    label="Product Material"
                    value={materialType}
                    onChange={(e) => setMaterialType(e.target.value)}
                    variant="outlined"
                    className="select"
                    size="small"
                  >
                    {fabricArr.map((option) => (
                      <MenuItem key={option.code} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    style={{ width: "250px" }}
                    select
                    label="Material Color"
                    value={materialColor}
                    onChange={(e) => setMaterialColor(e.target.value)}
                    variant="outlined"
                    className="select"
                    size="small"
                  >
                    {materialColorArray.map((option) => (
                      <MenuItem key={option.code} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {(productType === "Shoes" || productType === "Men Shoes") && (
                    <TextField
                      style={{ width: "250px" }}
                      select
                      label="Select Place"
                      value={materialPlace}
                      onChange={(e) => setMaterialPlace(e.target.value)}
                      variant="outlined"
                      className="select"
                      size="small"
                    >
                      {materialSolePlaceArray.map((option) => (
                        <MenuItem key={option.code} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  <TextField
                    style={{ width: "150px" }}
                    label="Courier Weight"
                    value={materialAmount}
                    onChange={(e) => setMaterialAmount(e.target.value)}
                    variant="outlined"
                    className="select"
                    size="small"
                  />
                  {/* consumption fields */}
                  <TextField
                    style={{ width: "200px" }}
                    size="small"
                    value={materialWeight}
                    onChange={(e) => setMaterialWeight(e.target.value)}
                    label={"material weight"}
                  />
                  <TextField
                    style={{ width: "200px" }}
                    select
                    label="Formula"
                    value={materialFormula}
                    onChange={(e) => setMaterialFormula(e.target.value)}
                    variant="outlined"
                    className="select"
                    size="small"
                  >
                    {[
                      { label: "in rolls", value: "IR" },
                      { label: "in meters", value: "IM" },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleMaterialObj}
                  >
                    Add
                  </Button>
                </Box>
              </Paper>
            </Collapse>
            <br />
            {materialObj.length > 0 ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Color</TableCell>
                      <TableCell align="left">Amount</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Place</TableCell>
                      <TableCell align="left">Id</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialObj.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.color}</TableCell>
                        <TableCell align="left">{row.amount}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.place}</TableCell>
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">
                          <DeleteIcon
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setMaterialObj(() => {
                                const toBeDeleted = materialObj.find(
                                  (x) => x.color === row.color
                                );
                                if (toBeDeleted)
                                  return materialObj.filter(
                                    (x) => x !== toBeDeleted
                                  );
                                else return materialObj;
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <h4 style={{ color: "red" }}>No Material Details are Provided</h4>
            )}
            <br />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                style={{ background: "black" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        ) : (
          ""
        )}
      </>
    </Container>
  );
};

export default EditProductsForm;
